import React, { useContext, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IconPlus } from '@tabler/icons-react';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

function NewPlanButton(props) {
    const { url, phone, setPhone, contact, setContact, user, token  } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [buyer, setBuyer] = useState({})
    const [selectedBuyer, setSelectedBuyer] = useState("")
    const [buyerOptions, setBuyerOptions] = useState([])
    const [date, setDate] = useState("")
    const [return_date, setReturnDate] = useState("")

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const isMobile = useMediaQuery('(max-width:600px)');

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
        setBuyer({});
        setBuyerOptions([]);
        setDate("");
        setReturnDate("");
    }

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyerOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post( url + 'api/buyers/plans/add', {
            user: user,
            customer: buyer.id,
            contact: contact,
            phone: phone,
            date: date,
            return_date: return_date,
            creator: 3,
            created_in_areas : JSON.stringify(props.createdInAreas),
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log("response", response);
            if(response.data.error == 0) {
                
                // window.location.reload();
                // navigate(0);
                props?.refetch ? props.refetch() : document.location.reload();
                handleClose();

            }
        })
        .catch(function (error) {
            handleClose();
            console.log(error);
        });
    }

    return (
        <div>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>New Plan</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <form onSubmit={handleSubmit}>
                    <InputLabel shrink>Buyer </InputLabel>
                <Autocomplete sx={{ mb: 2 }}
                    size="small"
                    options={ buyerOptions }
                    getOptionLabel={(option) => option.name ? option.name : '' }
                    onInputChange={(event, newInputValue) => { setBuyerOptions([]); fetchBuyers(newInputValue) }}
                    value={buyer}
                    onChange={(event, newValue) => {
                        setBuyer(newValue)
                    }}
                    isOptionEqualToValue={(option, value) => {                         
                        return option?.id == value?.id                        
                    }}
                    renderInput={(params) => <TextField {...params} required={false} />}
                />
                <InputLabel shrink>Date & Time</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth 
                            required={true} 
                            type="datetime-local" size="small"
                            value={date}
                            onChange={event => {
                                setDate(event.target?.value)
                            }}
                            InputLabelProps={{ shrink: true }} 
                            helperText={(return_date && !date ) ? "Please enter From Date" : "" }
                            FormHelperTextProps={{error: true}}
                            InputProps={{
                                inputProps: {
                                    max: return_date || "", 
                                },
                            }}
                        />
                        <InputLabel shrink>Return Date & Time</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required={true} type="datetime-local" size="small"
                            value={return_date}
                            onChange={event => {
                                const { value } = event.target;
                                console.log(value);
                                setReturnDate(value);
                            }} 
                            InputLabelProps={{ shrink: true }} 
                            helperText={(date && !return_date ) ? "Please enter Return Date" : "" }
                            FormHelperTextProps={{error: true}}
                            InputProps={{
                                inputProps: {
                                    min: date || "", 
                                },
                            }}
                            />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton sx={{ fontSize: isMobile ? "10px" : "14px" }} size="small" type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            {props.buttonType=="card" ?
                <Card sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }} onClick={handleOpen}>
                    { props.icon }
                    <Typography variant="body1" component="p">New Check-In</Typography>
                </Card>
                :
                <Fab color="primary" aria-label="add" onClick={handleOpen} 
                    sx={{
                        position: "fixed", 
                        bottom: (theme) => theme.spacing(2), 
                        right: (theme) => theme.spacing(2) 
                    }}
                    size={"medium"}
                >
                    <IconPlus />
                </Fab>
            }
        </div>
    )
}
export default NewPlanButton;