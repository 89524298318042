import React, { useContext, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';

function CheckinButton(props) {

    const { user, url, phone, contact, customer, token  } = useContext(GlobalContext);
    const navigate = useNavigate();
    
    const isMobile = useMediaQuery('(max-width:600px)');

    const [open, setOpen] = useState(false) //const open = false;
    const [date, setDate] = useState("")
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setLoading(false);
        setOpen(false);
        setDate("");        
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post( url + 'api/admin/checkin', {
            user: user,
            customer: customer,
            contact: contact,
            phone: phone,
            date: date,
            id: props.id,
            creator: 1,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            if(response.data.error == 0) {
                handleClose();
                props?.refetch ? props.refetch() : navigate(0);
            } else {
                setIsError(true);
                setErrorMessage(response.data.message);
                handleClose();
                // console.log({response})
            }
        })
        .catch(function (error) {
            setIsError(true);
            setErrorMessage(error?.message);
            handleClose();
            console.log(error);
        });
    }

    const clearError = () => {
        setIsError(false);
        setErrorMessage("")
    }

    return (
        <div style={{width: "100%", height: "100%"}}>
            <Button size="small" variant="contained" sx={{width: "100%", height: "100%", fontSize: isMobile ? "10px" : "12px"}} fullWidth onClick={handleOpen}>Check-In</Button>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle sx={{fontWeight: "bold"}}>Check-In</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <form onSubmit={handleSubmit}>
                        <InputLabel shrink>Date & Time</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required type="datetime-local" 
                            size="small"
                            value={date}
                            onChange={event => {
                                const { value } = event.target;
                                setDate(value);
                            }}
                            InputProps={{
                                inputProps: {
                                  max: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
                                },
                            }}
                             />
                        <Box sx={{ mt: 1 }}>
                            <LoadingButton sx={{ fontSize: isMobile ? "10px" : "14px" }} size="small" fullWidth type="submit" loading={loading} variant="contained">Check-In</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            {isError &&
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isError} autoHideDuration={3000} onClose={clearError}>
                    <Alert severity="error" sx={{ width: '100%' }} >
                        {errorMessage}
                    </Alert>
                </Snackbar>
            }
        </div>
    )
}
export default CheckinButton;