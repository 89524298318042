import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from "axios";
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../components/Layout';
import Fancybox from '../components/Fancybox';
import { GlobalContext } from '../contexts/GlobalContext'
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import LoadingComponent from '../components/LoadingComponent';
import Pusher from 'pusher-js';
import { Alert } from '@mui/material';

function Cast() {

    const { url, cdn, wsHost, wsKey, wsPort, phone, user, contact,  customer, token, portal } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState(""); 
    const [data, setData] = useState({});
    const [type, setType] = useState("");
    const inputRef = useRef(null);

    // console.log({url, cdn, wsHost, wsKey, wsPort, phone, user, contact,  customer, token, portal})

    useEffect(() => {
        const client = new Pusher(wsKey, {
			wsHost: wsHost,
            forceTLS: false,
            encrypted: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
		})

        const channel = client.subscribe('cast'); // create channels

        channel.bind("cast-event", function(data) {
        //    console.log({data});
           if(user == data?.user){
            setId(data?.id)
            setType(data?.type)
           }
        });

        channel.bind("pusher:subscription_succeded", function() {
            console.log("pusher:subscription_succeded");
        });

        channel.bind("pusher:subscription_error", function(error) {
            console.log("pusher:subscription_succeded", error);
        });        
    }, []);

    useEffect(() => {

        const fetchData = () => {

            let endPoint;
    
            if(type === "invoices" || type === "orders"){
                endPoint = 'api/voucher'
            }else if(type === "payments"){
                endPoint = 'api/payment'
            }
    
            axios.post( url + endPoint, {
                id: id,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setData(response.data)
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
        } 

       if(!!id) { 
            fetchData() 
        }

    }, [ id ]);

       

    useEffect(() => {
            console.log("inputRef", inputRef.current)
            if(inputRef.current != null) {
                inputRef.current.click()
            }
    }, [ inputRef.current ]);

    // console.log({dataReceived: data});

    const Info = ({details}) => {
        return (
            <Box sx={{width: "100%", mb:2}}>
                <Alert icon={false} severity="info">
                    <Box sx={{mb:2}}>#{details?.id}</Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1}}>
                        <Box sx={{display: "flex", gap: 2}}>
                            <div>Buyer:</div> 
                            <div>
                                <span style={{fontWeight: "bold"}}>{details?.buyerName}</span>, <span style={{ fontWeight: "lighter", fontSize: "0.8em", color: "gray"}}>{ details?.buyerCity }</span>
                            </div>
                        </Box>
                        <Box sx={{display: "flex", gap: 1}}>
                            <div>Supplier:</div> 
                            <div>
                                <span style={{fontWeight: "bold"}}>{details?.supplierName}</span>, <span style={{ fontWeight: "lighter", fontSize: "0.8em", color: "gray"}}>{ details?.supplierCity }</span>
                            </div>
                        </Box>
                        <Box sx={{display: "flex", gap: 1}}>
                            <div>Type:</div> 
                            <div>
                                <span style={{fontWeight: "bold"}}>{type?.toUpperCase() || ""}</span>
                            </div>
                        </Box>
                        <Box sx={{display: "flex", gap: 1}}>
                            <div>{type === "payments" && "Payment Number"} {type==="invoices"  && "Invoice Number"} { type === "orders" && "Voucher Number"} :</div> 
                            <div>
                                <span style={{fontWeight: "bold"}}>{type === "payments" && details?.paymentNumber } {type==="invoices" || type === "orders" && details?.invoiceNumber}</span>
                            </div>
                        </Box> 
                    </Box>                           
                </Alert>
            </Box>
        )
    }

    let details;

    if(type === "payments"){
        details = {
            buyerName: data?.payment?.buyer_details?.name,
            buyerCity: data?.payment?.buyer_details?.city_details?.name,
            supplierName: data?.payment?.seller_details?.name,
            supplierCity: data?.payment?.seller_details?.city_details?.name,
            paymentNumber: data?.payment?.payment_no,
            id: data?.payment?.id
        }
    }else if(type === "invoices" || type === "orders"){
        details = {
            buyerName: data?.voucher?.buyer_name,
            buyerCity: data?.voucher?.buyer_city,
            supplierName: data?.voucher?.seller_name,
            supplierCity: data?.voucher?.seller_city,
            invoiceNumber: data?.voucher?.voucher_no,
            id: data?.voucher?.voucher_no,
        }
    }

    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} loadingText={"Waiting for cast"} />
            : 
            <Layout title="Cast" nopadding={true}>
                <Info details={details} />
                <Fancybox options={{ Carousel: { infinite: false, open: true } }}>
                <Grid container spacing={2}>
                
                { (type === "invoices" || type === "orders") && JSON.parse(data?.voucher?.images).map((row, index) => {
                    const itemProps = index == 0 ? { ref: inputRef } : {};
                    return (                     
                    <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                        { row.type.includes("image") ?
                            <Box id={'item-'+index} sx={{ width: '100%', display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'column', gap: 2, p: 2 }}>
                                <Box sx={{ borderRadius: 2, background: "#000", height: "150px", width: "150px", overflow: "hidden", position: "relative"}}>
                                    <img {...itemProps} data-fancybox="gallery" href={cdn + data.voucher.voucher_type + '/' + row.file} src={cdn + data.voucher.voucher_type + '/' + row.file} style={{width: "90px", height: "auto", position: "absolute", width: "auto", height: "100%", left: "0", right: "0", top: "0", bottom: "0", margin: "auto"}} />
                                </Box>
                                <Box sx={{mt:1, display: 'flex', justifyContent:"center", alignItems:"center"}}>
                                    <Chip label="Image" variant="outlined" />
                                </Box>
                            </Box>
                            :
                            <Box  id={'item-'+index} sx={{ width: '100%', display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 2, p: 2 }}>
                                <div style={{wordBreak: "break-all"}}>
                                    <Box {...itemProps} sx={{ borderRadius: 2, background: "#000", height: "150px", width: "150px", overflow: "hidden", position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-fancybox="gallery" href={cdn + data.voucher.voucher_type + '/' + row.file}>
                                        {row.ext.toUpperCase()}
                                    </Box>
                                    <Box sx={{mt:1, display: 'flex', justifyContent:"center", alignItems:"center"}}>
                                        <Chip label="File" variant="outlined" />
                                    </Box>
                                </div>
                            </Box>
                        }
                    </Grid>
                    )
                })}

                { type === "payments" && JSON.parse(data?.payment?.images).map((row, index) => {
                    const itemProps = index == 0 ? { ref: inputRef } : {};
                    return (
                        <Grid item xs={6} sm={4} md={3} lg={2} key={index} >
                            { row.type.includes("image") ?
                                <Box id={'item-'+index} sx={{ width: '100%', display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'column', gap: 2, p: 2 }}>
                                    <Box sx={{ borderRadius: 2, background: "#000", height: "150px", width: "150px", overflow: "hidden", position: "relative"}}>
                                        <img {...itemProps} data-fancybox="gallery" href={cdn + 'payments/' + row.file} src={cdn + 'payments/' + row.file} style={{width: "90px", height: "auto", position: "absolute", width: "auto", height: "100%", left: "0", right: "0", top: "0", bottom: "0", margin: "auto"}} />
                                    </Box>
                                    <Box sx={{mt:1, display: 'flex', justifyContent:"center", alignItems:"center"}}>
                                        <Chip label="Image" variant="outlined" />
                                    </Box>
                                </Box>
                                :
                                <Box  id={'item-'+index} sx={{ width: '100%', display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 2, p: 2 }}>
                                    <div style={{wordBreak: "break-all"}}>
                                        <Box {...itemProps} sx={{ borderRadius: 2, background: "#000", height: "150px", width: "150px", overflow: "hidden", position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-fancybox="gallery" href={cdn + 'payments/' + row.file}>
                                            {row.ext.toUpperCase()}
                                        </Box>
                                        <Box sx={{mt:1, display: 'flex', justifyContent:"center", alignItems:"center"}}>
                                            <Chip label="File" variant="outlined" />
                                        </Box>
                                    </div>
                                </Box>
                            }
                        </Grid>                    
                    )
                })} 

                </Grid>
                </Fancybox>
            </Layout>
        }
        </>
    );
}

export default Cast;
