import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, Fab, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { IconSearch } from '@tabler/icons-react'
import axios from 'axios';
import React, { useContext,  useState } from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

dayjs.extend(utc);
dayjs.extend(timezone);

function FilterButton(props) {

    const { setBuyer, setSupplier, setFromDate, setToDate, buyer, supplier, setFilterApplied, currentTab, currentStatus, setCurrentStatus} = props;

    const { url, user, token  } = useContext(GlobalContext);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [selectedBuyer, setSelectedBuyer] = useState("")
    const [selectedSupplier, setSelectedSupplier] = useState("")
    const [selectedFromDate, setSelectedFromDate] = useState()
    const [selectedToDate, setSelectedToDate] = useState()
    const [buyerOptions, setBuyerOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const [selectedCurrentStatus, setSelectedCurrentStatus] = useState(99)

    const [loading, setLoading] = useState(false);
    
    function handleOpen(){
        setModalOpen(true);
    }

    function handleClose(){
        setModalOpen(false);
        setSelectedBuyer("");
        setSelectedSupplier("");
        setSelectedFromDate();
        setSelectedToDate();
        setSelectedCurrentStatus(99);
        setBuyerOptions([]);
        setSupplierOptions([]);
    }
    
    function handleSubmit(){
        if((selectedFromDate && selectedToDate) || (!selectedFromDate && !selectedToDate)){
            setLoading(true);
            setBuyer && setBuyer(selectedBuyer);
            setSupplier && setSupplier(selectedSupplier);
            setFromDate && setFromDate(selectedFromDate);
            setToDate && setToDate(selectedToDate);
            setCurrentStatus && setCurrentStatus(selectedCurrentStatus)
            handleClose();
            setLoading(false);
            setFilterApplied(true)
            }
    }

    function clearFilter(){
        setLoading(true);
        setBuyer && setBuyer("");
        setSupplier && setSupplier("");
        setFromDate && setFromDate("");
        setToDate && setToDate("");
        handleClose();
        setLoading(false);
    }

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyerOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const fetchSuppliers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "supplier",
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setSupplierOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
    

  return (
    <>
        <Fab color="primary" aria-label="add" onClick={handleOpen} 
            sx={{ 
                position: "fixed", 
                bottom: (theme) => theme.spacing(5), 
                left: (theme) => theme.spacing(5) 
            }}
            size={"medium"}
        >
            <IconSearch />
        </Fab>
        
        <Dialog onClose={handleClose} open={modalOpen}>
            <DialogTitle>Filter</DialogTitle>
            <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>

                { setBuyer &&
                (<>
                <InputLabel shrink>Buyer </InputLabel>
                <Autocomplete sx={{ mb: 2 }}
                    options={ buyerOptions }
                    getOptionLabel={(option) => option.name ? option.name : '' }
                    onInputChange={(event, newInputValue) => {  setBuyerOptions([]); fetchBuyers(newInputValue) }}
                    value={selectedBuyer}
                    onChange={(event, newValue) => {
                        setSelectedBuyer(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} required={false} />}
                />
                </>)}

                { setSupplier && (
                <>
                <InputLabel shrink>Supplier </InputLabel>
                <Autocomplete sx={{ mb: 2 }}
                    options={ supplierOptions }
                    getOptionLabel={(option) => option.name ? option.name : '' }
                    onInputChange={(event, newInputValue) => { setSupplierOptions([]); fetchSuppliers(newInputValue) }}
                    value={selectedSupplier}
                    onChange={(event, newValue) => {
                        setSelectedSupplier(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} required={false} />}
                />
                </>)}

                <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <InputLabel shrink>From Date </InputLabel>
                    { (selectedToDate && !selectedFromDate ) ? <InputLabel shrink sx={{color: "red"}}>Select From Date </InputLabel> : <></> }
                    <DatePicker sx={{ mb: 2, width: "100%" }}
                        timezone='Asia/Kolkata'
                        value={selectedFromDate} 
                        onChange={ (newDate) =>  setSelectedFromDate(newDate)}
                        format={'DD/MM/YYYY'}
                        maxDate={selectedToDate ? selectedToDate : ""}
                        slotProps={{
                            textField: {
                                InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                    <CalendarMonthIcon />
                                    </InputAdornment>
                                ),
                                },
                            },

                        }}                        
                    />

                    <InputLabel shrink>To Date </InputLabel>
                    { (selectedFromDate && !selectedToDate ) ? <InputLabel shrink sx={{color: "red"}}>Select To Date </InputLabel> : <></> }
                    <DatePicker sx={{ mb: 2, width: "100%" }}
                        timezone='Asia/Kolkata'
                        value={selectedToDate}
                        onChange={  (newDate) =>  setSelectedToDate(newDate)    }
                        format={'DD/MM/YYYY'}
                        minDate={selectedFromDate ? selectedFromDate : ""}
                        slotProps={{
                            textField: {
                                InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                    <CalendarMonthIcon />
                                    </InputAdornment>
                                ),
                                },
                            },
                        }}
                    />
                    
                </LocalizationProvider>

                {
                    (currentTab?.name === "Checkins" || currentTab?.name === "CustomOrders" || currentTab?.name === "Plans") && 
                    <>
                    <InputLabel shrink>Select status</InputLabel>
                    <Select
                        sx={{ mb: 2 }}
                        fullWidth
                        value={selectedCurrentStatus}
                        onChange={(event) => setSelectedCurrentStatus(event.target.value) }
                    >
                        {
                            Object.entries(currentTab.status).map( ([key, value], index) => (<MenuItem value={+key} key={index}>{value}</MenuItem>))
                        }
                    </Select>
                    </>
                }

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                    <LoadingButton loading={loading} onClick={handleSubmit} variant="contained">Filter</LoadingButton>
                    <LoadingButton loading={loading}  onClick={clearFilter} variant="contained" color="error">Clear Filter</LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    </>
  )
}

export default FilterButton