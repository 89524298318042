import React, { useEffect, useState, useContext, useRef } from 'react'
import Layout from '../components/Layout';
import Chatter from '../components/Chatter';
import { useParams } from "react-router-dom";
import { Chip, Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import MediaGrid from '../components/MediaGrid';
import axios from 'axios';
import { GlobalContext } from '../contexts/GlobalContext';
import { format, parse } from 'date-fns'

function Order() {
    let { id } = useParams();

    const { url,  token, } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Order View");
        }
    }, [ ]);

    const fetchData = async () => {
        await axios.post( url + `api/admin/voucher/${id}`, {}, { 
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(function (response) {
            setData(response.data);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const FloatDetails = () => {

        return (
            <Paper sx={{ border: 1, borderBottom: 1,  borderColor: 'divider', textAlign: 'center', m:2 }}>
                <Box sx={{display: "flex"}}>
                <Box sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 2, py:1, flex:1}}>
                    <Typography variant="overline" component="p">Buyer</Typography>
                    <Typography variant="body" component="p">{data.voucher?.buyer_name}{!!data?.voucher?.buyer_city && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data.voucher.buyer_city}</span>}</Typography>
                    {!!data?.voucher?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.voucher?.buyer_msme} /></Box>}
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1, flex:1}}>
                    <Typography variant="overline" component="p">Supplier</Typography>
                    <Typography variant="body" component="p">{data.voucher?.seller_name}{!!data?.voucher?.seller_city && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data.voucher.seller_city}</span>}</Typography>
                    {!!data?.voucher?.seller_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.voucher?.seller_msme} /></Box>}
                </Box>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{ data.voucher.date ? format(parse(data.voucher.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : "-" }</Typography>
                </Box>
                <Box sx={{ borderColor: 'divider', px: 1, py:1 }}>
                    <Typography variant="overline" component="p">Remarks</Typography>
                    <Typography variant="body" component="p">{data.voucher.remarks ? data.voucher.remarks : '-'}</Typography>
                </Box>
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={data.voucher.images} />
                </Box>
            </Paper>
        )
    }

    return (
        <div>
            <Layout title="Chat" nopadding={true}>
                {
                    (data.voucher?.order_type == 4 && data.voucher?.chat_channel != null) &&
                        <Chatter id={data.voucher.chat_channel} FloatDetails={FloatDetails}/>
                }
            </Layout>
        </div>
    );
}

export default Order;
