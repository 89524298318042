import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Fancybox from './Fancybox';
import { GlobalContext } from '../contexts/GlobalContext'
import PdfViewer from './PdfViewer';

function MediaGrid(props) {
    const { cdn  } = useContext(GlobalContext);

    const openLink = (link) => { window.open(link, '_blank').focus() }

    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 15);
    const gallery_name = `${timestamp}_${randomString}`;

    const handlePdfPress = (url) => {
        openLink(cdn + props.dir + '/' + url);
    }
     
    if(props.images) {
        var images = JSON.parse(props.images);
        if(Object.keys(images).length !== 0) {
            return (
                <>
                    <Fancybox options={{ Carousel: { infinite: false } }}>
                        <Box container spacing={2} sx={{ p: 2, gap: 2 }} direction="row" wrap="nowrap" style={{ display: 'flex', overflowX: "auto", scrollbarWidth: "thin", scrollbarColor: "darkgray lightgray" }}>
                            {images.map((row, index) => (
                                <Box sx={{ width: '180px' }} key={index}>
                                    <Card sx={{ border: 1, borderColor: 'divider', width: '100%', maxWidth: '100%', textAlign: 'center' }}>
                                        { row.type.includes("image") ?
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'column', gap: 2, p: 2 }}>
                                                <Box sx={{ borderRadius: 2, background: "#000", height: "150px", width: "150px", overflow: "hidden", position: "relative"}}>
                                                    <img  data-fancybox={gallery_name} alt={"uploaded data"} href={cdn + props.dir + '/' + row.file} src={cdn + props.dir + '/' + row.file} style={{width: "90px", height: "auto", position: "absolute", width: "auto", height: "100%", left: "0", right: "0", top: "0", bottom: "0", margin: "auto"}} />
                                                    </Box>
                                                <Box sx={{mt:1}}>
                                                    <Chip label="Image" variant="outlined" />
                                                </Box>
                                            </Box>
                                        :
                                            row.type.includes("pdf") ?
                                                <>
                                                    <Box sx={{ 
                                                        width: '100%', display: 'flex', justifyContent:"center", 
                                                        alignItems:"center", flexDirection: 'column', gap: 2, p: 2 
                                                        }}
                                                    >
                                                        
                                                            <Box sx={{ borderRadius: 2, background: "#fff", height: "150px", width: "150px", overflow: "hidden", position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
                                                                href={cdn + props.dir + '/' + row.file}
                                                                onClick={() => handlePdfPress(row.file)}
                                                            >
                                                                <Box >
                                                                <Typography>{row.ext.toUpperCase()}</Typography> 
                                                                <Typography sx={{fontSize:"75%"}}>{"Click here to open"}</Typography>
                                                                </Box>
                                                                
                                                            </Box>
                                                            <Box sx={{mt:1}}>
                                                                <Chip label="PDF" variant="outlined" />
                                                            </Box>
                                                        
                                                    </Box>
                                                </>
                                                :
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'column', gap: 2, p: 2 }}>
                                                    <Box sx={{ borderRadius: 2, background: "#000", height: "150px", width: "150px", overflow: "hidden", position: "relative"}}>
                                                        <img  data-fancybox={gallery_name} alt={"uploaded data"} href={cdn + props.dir + '/' + row.file} src={cdn + props.dir + '/' + row.file} style={{width: "90px", height: "auto", position: "absolute", width: "auto", height: "100%", left: "0", right: "0", top: "0", bottom: "0", margin: "auto"}} />
                                                    </Box>
                                                    <Box sx={{mt:1}}>
                                                        <Chip label="File" variant="outlined" />
                                                    </Box>
                                                </Box>
                                        }   
                                    </Card>
                                </Box>
                            ))}
                        </Box>
                    </Fancybox>
                </>
            )
        } else {
            return (
                <Box sx={{ width: '100%', display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 2, p: 2 }}>
                    <Typography variant="p" component="div" sx={{mb:0}}>No media attached</Typography>
                </Box>
            )
        }
    }
}

export default MediaGrid;