import React, { useState, useEffect } from "react";
  
export const GlobalContext = React.createContext();
export const GlobalContextProvider = ({ children }) => {

    const wsHost = "ws.rasiklalsons.in";

    let urlTemp;
    let cdnTemp;
    // if (process.env.REACT_APP_DEV_MODE === "development"){
    //     // urlTemp = "https://rasiklal.93lab.com/";
    //     urlTemp = "https://www.rs-stage.awpws.in/"
    //     // urlTemp = "https://rs.awpws.in/";
    //     cdnTemp = "https://cdn.rasiklalsons.in/stg/";
    // }else if (process.env.REACT_APP_DEV_MODE === "production"){
    //     urlTemp = "https://erp.rasiklalsons.in/";
    //     cdnTemp = "https://cdn.rasiklalsons.in/prod/";
    // }

    if (process.env.REACT_APP_DEV_MODE === "development"){
        // urlTemp = "https://rasiklal.93lab.com/";
        // urlTemp = "https://rs.awpws.in/";
        // urlTemp = "https://rs-t.awpws.in/";
        urlTemp = "https://rs-s.awpws.in/";
        // urlTemp = "https://www.rs-stage.awpws.in/"
        // urlTemp = "https://rs.awpws.in/"
        cdnTemp = "https://cdn.rasiklalsons.in/stg/";
    }else if(process.env.REACT_APP_DEV_MODE === "beta"){ 
        urlTemp = "https://rs.awpws.in/"
        cdnTemp = "https://cdn.rasiklalsons.in/stg/";
    }else if (process.env.REACT_APP_DEV_MODE === "production"){
        urlTemp = "https://erp.rasiklalsons.in/";
        cdnTemp = "https://cdn.rasiklalsons.in/prod/";
    }
    const url = urlTemp;
    const cdn = cdnTemp

    const wsKey = "rasiklal-sock-key";
    const wsPort = 443;

    //loggedIn
    const [loggedIn, setLoggedIn] = useState ( () => {
        const savedItem = localStorage.getItem("loggedIn");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("loggedIn", loggedIn)
    }, [loggedIn]);
    
    //token
    const [token, setToken] = useState ( () => {
        const savedItem = localStorage.getItem("token");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("token", token)
    }, [token]);

    //user
    const [user, setUser] = useState ( () => {
        const savedItem = localStorage.getItem("user");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("user", user)
    }, [user]);

    //username
    const [username, setUserName] = useState ( () => {
        const savedItem = localStorage.getItem("username");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("username", username)
    }, [username]);

    //pushToken
    const [pushToken, setPushToken] = useState ( () => {
        const savedItem = localStorage.getItem("pushToken");
        const parsedItem = savedItem;
        return parsedItem || '';
    });

    useEffect(() => {
        localStorage.setItem("pushToken", pushToken)
    }, [pushToken]);

    //masters
    const [masters, setMasters] = useState ( () => {
        const savedItem = localStorage.getItem("masters");
        const parsedItem = savedItem;
        return JSON.parse(parsedItem) || '';
    });

    useEffect(() => {
        localStorage.setItem("masters", JSON.stringify(masters))
    }, [masters]);


    return (
        <GlobalContext.Provider value={{ url, cdn, wsHost, wsKey, wsPort, loggedIn, setLoggedIn, token, setToken, user, setUser, username, setUserName, pushToken, setPushToken, masters, setMasters }}>
            {children}
        </GlobalContext.Provider>
    );
};