import React, { useEffect, useState, useContext, Fragment } from 'react'
import axios from "axios";
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../components/Layout';
import UploadButton from '../components/VoucherUploader';
import { GlobalContext } from '../contexts/GlobalContext';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { red, amber, green } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import MediaGrid from '../components/MediaGrid';
import Chatter from '../components/Chatter';
import { Divider, useMediaQuery } from '@mui/material';
import LoadingComponent from '../components/LoadingComponent';

export default function OrderView() {
    let { id } = useParams();
    const { url, cdn, phone,  contact,  customer, token, portal  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [title, setTitle] = useState("Order");

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Order View");
        }
    }, [ ]);

    const fetchData = async () => {
        await axios.post( url + 'api/voucher', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setData(response.data);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Info = () => {
        if (data.hasOwnProperty('voucher')) { 
        return (
            <>
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                    
                    <Grid container>
                        <Grid item xs={6}  sx={{ borderBottom: 1, borderRight: 1,  borderColor: 'divider', px: 2, py:1 }}>
                            <Typography variant="overline" component="p">Buyer</Typography>
                            <Typography variant="body" component="p">{data.voucher?.buyer_name}{!!data?.voucher?.buyer_city && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data.voucher.buyer_city}</span>}</Typography>
                            {!!data?.voucher?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.voucher?.buyer_msme} /></Box>}
                        </Grid>
                        <Grid item xs={6}  sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                            <Typography variant="overline" component="p">Supplier</Typography>
                            <Typography variant="body" component="p">{data.voucher?.seller_name}{!!data?.voucher?.seller_city && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data.voucher.seller_city}</span>}</Typography>
                            {!!data?.voucher?.seller_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.voucher?.seller_msme} /></Box>}                        
                        </Grid>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Order #</Typography>
                            <Typography variant="body" component="p">{data.voucher.voucher_no || '-'}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Date</Typography>
                            <Typography variant="body" component="p">{!!data.voucher.date ? format(parse(data.voucher.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            {data.voucher.total ? 
                            <Typography variant="body" component="p">&#8377; {data.voucher.total} </Typography>
                            : 
                            <Typography variant="body" component="p">-</Typography>
                            }
                        </Grid>
                        <Grid item xs={6} sx={{ borderBottom: 1,borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Delivery From</Typography>
                            <Typography variant="body" component="p">{!!data.summary.delivery_from ? format(parse(data.summary.delivery_from, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderBottom: 1,borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Delivery To</Typography>
                            <Typography variant="body" component="p">{!!data.summary.delivery_to ? format(parse(data.summary.delivery_to, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{ data.voucher.remarks || '-'}</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                        <MediaGrid dir="orders" images={data.voucher.images} />
                    </Box>
                </Paper>

                { portal == "supplier" && (parseInt(data.voucher.order_status) == 0 || parseInt(data.voucher.order_status) == 1) &&
                    <UploadButton title="Invoice" buyer={data.voucher.buyer} seller={data.voucher.seller} link={data.voucher.id} voucher_type="invoices" />
                }
            </>
        )
        } else {
            return (
                <Card sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, px: 2, py:2 }}>
                    <Typography variant="overline" component="p">Loading...</Typography>
                </Card>
            )
        }
    }

    const ItemRow = (props) => {
        const rowBackgroundColors = {
            "excess": amber[100], // just for example, remove it if you don't need
            "match": green[100],
            "less": red[100],
          };

        const { row, index } = props;
        const [open, setOpen] = React.useState(false);

        if(!row.hasOwnProperty('invoiced')){
            row.invoiced = 0;
        }
        var bgClass = "less";
        if(parseFloat(row.invoiced) == parseFloat(row.qty)) {
            bgClass = "match";
        } else if(parseFloat(row.invoiced) > parseFloat(row.qty)) {
            bgClass = "excess";
        }

        if(row.image == null) {
            var images = [];
        } else {
             images = JSON.parse(row.image);
        }

        return (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: rowBackgroundColors[bgClass] }}>
              <TableCell>
                <IconButton sx={{color:"#000"}} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>{open ? <IconChevronUp /> : <IconChevronDown />}</IconButton>
              </TableCell>
              <TableCell align="left" sx={{color:"#000"}}>{index}</TableCell>
              <TableCell align="left" sx={{color:"#000"}}>{row.particular}</TableCell>
              <TableCell align="left" sx={{color:"#000"}}>{row.category_details?.name || ""}</TableCell>
              <TableCell align="right" sx={{color:"#000"}}>{row.rate}</TableCell>
              <TableCell align="right" sx={{color:"#000"}}>{row.qty}</TableCell>
              <TableCell align="right" sx={{color:"#000"}}>{row.invoiced}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">Details</Typography>
                    <Grid container>
                        {
                            JSON.parse(row.attributes).length > 0 &&
                            JSON.parse(row.attributes).map( attribute => {
                                
                                return (
                                    <Fragment key={attribute?.id}>
                                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                                            <Typography variant="overline" component="p">{attribute.name}</Typography>
                                            <Typography variant="body" component="p">{attribute.value}</Typography>
                                        </Grid>
                                    </Fragment>
                                )
                            })
                        }
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Discount (%)</Typography>
                            <Typography variant="body" component="p">{row.discount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total Discount (&#8377;)</Typography>
                            <Typography variant="body" component="p">&#8377;{row.total_discount_amount}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Tax (%)</Typography>
                            <Typography variant="body" component="p">{row.tax}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            <Typography variant="body" component="p">&#8377;{row.subtotal}</Typography>
                        </Grid>
                        {
                            Object.keys(images).length ?
                            <Grid item xs={6} md={3} sx={{ px: 1, py:1 }}>
                                <img style={{ width: 140+"px", height: "auto", borderRadius: "4px" }} alt={"uploaded data"} src={cdn + 'catalog/' + images[0].file} data-fancybox="products" href={cdn + 'catalog/' + images[0].file}/>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                    
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        );
    }

    const FloatDetails = () => {
        return (
            <Paper sx={{ border: 1, borderBottom: 1,  borderColor: 'divider' }}>
                <Box sx={{ borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.voucher.buyer_name : data.voucher.seller_name }</Typography>  
                </Box>
                <Box sx={{ borderColor: 'divider', px: 1, py:1 }}>
                    <Typography variant="overline" component="p">Remarks</Typography>
                    <Typography variant="body" component="p">{data.voucher.remarks ? data.voucher.remarks : '-'}</Typography>
                </Box>
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={data.voucher.images} />
                </Box>
            </Paper>
        )
    }

    const Invoice = (prop) => {
        if(prop.data.summary) {
            prop.data.summary = JSON.parse(prop.data.summary);
        }
        return (
            <Card sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Grid container>
                    { prop.type == 0 ?
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Chip label="Unprocessed" />
                    </Grid>
                    : null
                        }
                    <Grid item xs={4} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Invoice #</Typography>
                        <Typography variant="body" component="p">{prop.data.voucher_no ? prop.data.voucher_no : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{prop.data.date ? format(parse(prop.data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        <Typography variant="body" component="p">&#8377; {prop.data.total ? prop.data.total : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Due</Typography>
                        <Typography variant="body" component="p">&#8377; {prop.data.summary ? (prop.data.summary.due ? prop.data.summary.due : '') : '-'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Due Date</Typography>
                        <Typography variant="body" component="p">{prop.data.summary ? (prop.data.summary.due_date ? format(parse(prop.data.summary.due_date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '') : '-'}</Typography>
                    </Grid>
                </Grid>
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="invoices" images={prop.data.images} />
                </Box>
            </Card>
        )
    }

    const Invoices = () => {
        return (
            <>
            <Typography variant="h6" component="div">Invoices</Typography>
            {data.entries && data.entries?.map((row, index) => (
                <Invoice type={0} key={index} data={row}></Invoice>
            ))}
            {data.invoices && data.invoices?.map((row, index) => (
                <Invoice type={1} key={index} data={row}></Invoice>
            ))}

            {
                (Object.keys(data.entries).length + Object.keys(data.invoices).length) == 0 ?
                    <Typography variant="caption" component="div" sx={{mb:4}}>No invoice has been added yet</Typography>
                    :
                    null

            }
            </>
        )
    }

    const isMobile = useMediaQuery('(max-width:600px)');

    const Address = () => {

        const [address, setAddress] = useState("billing")

        const BillingAddress = () => {
            if(data.voucher?.billing_address === null){
                return <></>
            }
            return(
                <>
                    { isMobile ? <></> : <Typography variant="subtitle1" component="p" sx={{textDecoration: "underline", marginBottom: "5px"}}>Billing Address</Typography>}
                    <Typography variant="body" component="p">Name: {data.voucher?.billing_address?.name || ""}</Typography>
                    <Typography variant="body" component="p">Address Line 1: {data.voucher?.billing_address.address_1 || ""}</Typography>
                    <Typography variant="body" component="p">Address Line 2: {data.voucher?.billing_address.address_2 || ""}</Typography>
                    <Typography variant="body" component="p">Area: {data.voucher?.billing_address.area || ""}</Typography>
                    <Typography variant="body" component="p">City: {data.voucher?.billing_address.city_details.name || ""}</Typography>
                    <Typography variant="body" component="p">State: {data.voucher?.billing_address.state_details.name || ""}</Typography>
                    <Typography variant="body" component="p">Country: {data.voucher?.billing_address.country_details.name || ""}</Typography>
                    <Typography variant="body" component="p">PIN: {data.voucher?.billing_address.pincode || ""}</Typography>
                    <Typography variant="body" component="p">Phone: {data.voucher?.billing_address.cc_phone || ""}</Typography>
                </>
            )
        }        

        const ShippingAddress = () => {
            if(data.voucher?.shipping_address === null){
                return <></>
            }
            return (
                <>
                    { isMobile ? <></> :  <Typography variant="subtitle1" component="p" sx={{textDecoration: "underline", marginBottom: "5px"}}>Shipping Address</Typography>}
                    <Typography variant="body" component="p">Name: {data.voucher?.shipping_address?.name || ""}</Typography>
                    <Typography variant="body" component="p">Address Line 1: {data.voucher?.shipping_address.address_1 || ""}</Typography>
                    <Typography variant="body" component="p">Address Line 2: {data.voucher?.shipping_address.address_2 || ""}</Typography>
                    <Typography variant="body" component="p">Area: {data.voucher.shipping_address.area || ""}</Typography>
                    <Typography variant="body" component="p">City: {data.voucher?.shipping_address.city_details.name || ""}</Typography>
                    <Typography variant="body" component="p">State: {data.voucher?.shipping_address.state_details.name || ""}</Typography>
                    <Typography variant="body" component="p">Country: {data.voucher?.shipping_address.country_details.name || ""}</Typography>
                    <Typography variant="body" component="p">PIN: {data.voucher.shipping_address.pincode || ""}</Typography>
                    <Typography variant="body" component="p">Phone: {data.voucher?.shipping_address.cc_phone || ""}</Typography> 
                </>
            )
        }

        return (            
            <>
            <Typography  variant="h6" component="div" sx={{mb:2, mt:2}}>Address</Typography>
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', mt: 1, mb: 4, p: 1 }}>
                { isMobile ? 
                <> 
                <Box sx={{display: "flex", justifyContent: "space-evenly", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                <Chip label="Billing Address"  onClick={() => setAddress("billing")} color="primary" variant={ (address === "shipping" ? "outlined" : "filled")} />
                <Chip label="Shipping Address"  onClick={() => setAddress("shipping")} color="primary" variant={ (address === "billing" ? "outlined" : "filled")} />
                </Box>

                <Box sx={ {padding: "5px"} }>
                    { (address === "billing") ? <BillingAddress /> : <ShippingAddress /> }
                </Box> 
                </> 
                : 
                <>
                <Box sx={ {display: "flex", padding: "5px"} }>
                    <Box sx= {{flex: 1,  padding: "10px"}}>
                        <BillingAddress />                    
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box sx= {{flex: 1, padding: "10px"}}>
                        <ShippingAddress />                                       
                    </Box>
                </Box>
                </> }
            </Paper>
            </>         
        )
    }

    const Items = () => {
        return (
            <Box>
            {data.voucher?.clients_voucher_items ?
                <>
                    <TableContainer component={Paper} sx={{ mb: 2 }}>
                        <Table stickyHeader aria-label="collapsible table">
                            <TableHead>
                            <TableRow>
                                <TableCell width="30px" />
                                <TableCell width="50px" align="left" size="small">No.</TableCell>
                                <TableCell align="left">Particular</TableCell>
                                <TableCell align="left">Category</TableCell>
                                <TableCell align="right">Rate</TableCell>
                                <TableCell align="right">Qty</TableCell>
                                <TableCell align="right">Invoiced</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {(data.voucher?.clients_voucher_items)?.map((row, index) => (
                                <ItemRow key={row.sno} row={row} index={index} />
                            ))}
                            {(data?.summary?.totalQty || data?.summary?.total) &&
                            <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Box sx={{width: "100%", padding: "10px", display: "flex", flexDirection: "row"}}>
                                    {data?.summary?.totalQty &&
                                    <Box sx={{ flex: 1}}>
                                        <Typography variant="body2">Total Qty: {data?.summary?.totalQty}</Typography>
                                    </Box>}
                                    {data?.summary?.total &&
                                    <Box sx={{ flex: 1}}>
                                        <Typography variant="body2">Total Amount: &#8377;{data?.summary?.total}</Typography>
                                    </Box>}                                    
                                </Box>
                            </TableCell>
                            </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1, mb: 2 }}>
                        <Chip label="Pending" sx={{color: "#000", backgroundColor: red[100]}} />
                        <Chip label="Invoiced" sx={{color: "#000", backgroundColor: green[100]}} />
                        <Chip label="Excess Invoiced" sx={{color: "#000", backgroundColor: amber[100]}} />
                    </Box>
                </>
            :
            <></>
            }
            </Box>
        )
    }

    // const OrderDetails = () => {
        
    //     if(data.voucher.order_type == 4 && data.voucher.chat_channel != null) {
    //         setTitle("Custom Order");
    //         return (
    //             <>
    //                 <Chatter id={data.voucher.chat_channel} FloatDetails={FloatDetails} />
    //             </>
    //         )
    //     }

    //     return (
    //         <>
    //             <Info />
    //             <Address />
    //             <Items />
    //             <Invoices />
    //         </>
    //     )
        
    // }

    return (
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title={title}>
                {/* <OrderDetails /> */}
                
                <Info />
                <Address />
                <Items />
                <Invoices />
                
            </Layout>
        }
        </>
    );
}

