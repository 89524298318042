import { Alert, AlertTitle, Button, Chip, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import ScrollComponent from "../../components/ScrollComponent";
import { GlobalContext } from "../../contexts/GlobalContext";
import BackToTop from "../../components/BackToTop";
import ErrorMessage from "../../components/ErrorMessage";
import { FilterDataContext } from "../../contexts/FilterDataContext";
import MediaGrid from "../../components/MediaGrid";



export default function FilteredCustomOrders(){

    const navigate = useNavigate();

    const { filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, } = useContext(FilterDataContext);
    
    const { url, user, token,  } = useContext(GlobalContext);

    const title = "Filtered Custom Orders";

    const isMobile = useMediaQuery('(max-width:600px)');

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/admin/customorders?page=' + page, {
            user: user,
            buyer: filterDataBuyer?.id ? filterDataBuyer?.id : "",
            supplier: filterDataSupplier?.id ? filterDataSupplier?.id : "",
            from_date: filterDataFromDate,
            to_date: filterDataToDate,
            status: filterDataStatus === "allData" ? "" : filterDataStatus.toString(),

        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    } 
        
    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'filteredCustomOrders',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const currentTab = { name: "CustomOrders", status: {  1: "Ordered", 2: "Completed", 99: "All Data",}};

    // console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus,})
    
    const Item = ({data}) => {
        let reason = '';
        if(data?.approval == 2 && data?.approval_log) {
            reason = JSON.parse(data.approval_log)[0]?.reason || "-";
        }

        function redirectOnClick() {   
                if (data.entry_id && data.order_type == 4) {
                    navigate("/order/" + data.id) 
                }else{
                    navigate("/order/" + data.voucher_id) 
                }
        }



        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                    {
                        filterDataStatus === 'allData' && !!data?.status_label &&
                        <Box sx={{py:1, borderBottom: 1, borderColor: 'divider'}}>
                            <Chip label={data?.status_label} />
                        </Box>
                    }
                    <Box>
                    <Box sx={{display: "flex", py:1}}>
                        <Box sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 2, py:1, flex: 1 }}>
                            <Typography variant="overline" component="p">Buyer</Typography>
                            <Typography variant="body" component="p">{data?.buyer_name}{!!data?.buyer_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.buyer_city_details?.name}</span>}</Typography>
                            {
                                !!data?.buyer_msme  &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                            }
                        </Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1, flex: 1 }}>
                            <Typography variant="overline" component="p">Supplier</Typography>
                            <Typography variant="body" component="p">{data?.seller_name}{!!data?.seller_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.seller_city_details?.name}</span>}</Typography>
                            {
                                !!data?.seller_msme  &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.seller_msme} /></Box>
                            }

                        </Box>
                    </Box>
                    <Grid container>
                        {
                            !(data?.status == 1 && data?.approval == 0) ?
                            <>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Order #</Typography>
                                    <Typography variant="body" component="p">{data.voucher_no ? data.voucher_no : '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Date</Typography>
                                    <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Total</Typography>
                                    {data?.total ?  <Typography variant="body" component="p">&#8377; {data?.total} </Typography> : <Typography variant="body" component="p">-</Typography> }
                                </Grid>
                            </>
                            :
                            <Grid item xs={12} sx={{ px: 1, py:1, borderBottom: 1, borderColor: 'divider', }}>
                                <Typography variant="overline" component="p">Date</Typography>
                                <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                            </Grid>
                        }

                        <Grid item xs={12} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data.remarks ? data.remarks : '-'}</Typography>
                        </Grid>                        
                        {
                           data?.status == 1 && data?.approval == 2 ?
                            <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider',px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Rejection Reason</Typography>
                                <Typography variant="body" component="p">{reason ? reason : '-'}</Typography>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                </Box>
                
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={data.images} />
                </Box>
                
                {/* { status == 0 &&
                    <CustomOrderActions approval={data.approval} id={data.id} />
                } */}

                <Box sx={{borderTop: 1, borderColor: 'divider', p:1, mt:1, display: "flex", gap:1}}>
                    {
                        (data.status == 6 || data.status == 2) &&
                        <Box sx={{flex: 1}}>
                            <Button variant="contained" fullWidth onClick={()=>{navigate("/orderview/" + data?.id)}} >View Order</Button>
                        </Box>
                    }
                    <Box sx={{flex: 1}}>
                        <Button variant="contained" fullWidth sx={isMobile ? {} : {px: 10}} onClick={redirectOnClick}>Order Chat</Button>
                    </Box>
                </Box>

            </Paper>
        )
    }


    if(filterDataStatus === ""){
        navigate(-1, { replace: true })
        return null;
    }

    return(
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
                <>            
                {  isLoading ? 
                <LoadingComponent loading={isLoading} />
                : 
                <Layout title={title} backToTop={true}>                
                    <Box>
                        <Alert severity="success" >
                            <AlertTitle sx={{fontSize: "0.5 em", fontWeight: "bold"}}> Results </AlertTitle>
                            <>
                                {filterDataBuyer &&  <Typography variant="subtitle2"><b>Buyer :</b> {filterDataBuyer?.name?.toUpperCase()}</Typography>}
                                {filterDataSupplier  && <Typography variant="subtitle2"><b>Supplier :</b> {filterDataSupplier?.name?.toUpperCase()}</Typography>}
                            </>
                            {
                                (filterDataFromDate && filterDataToDate) && 
                                    <Typography variant="subtitle2"> <b>From:</b> {format(new Date(filterDataFromDate), 'dd/MM/yyyy')} <b>To:</b> {format(new Date(filterDataToDate), 'dd/MM/yyyy')}</Typography>
                            }    
                            <Box sx={{mt: 1}}>
                                { filterDataStatus == "allData" ? `Status: ${currentTab.status[99]}`: `Status: ${currentTab.status[filterDataStatus]}`}
                            </Box>
                        </Alert>                    
                    </Box>                
                    <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    <BackToTop alone={true} />
                </Layout>
                }
                </>
            } 
        </>       
    )
}