import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import Layout from "./Layout";

export default function ErrorMessage({title, message}) {
    return (
        <>
            <Layout title={title} >
                <Alert severity="error">
                    <Box>{message}</Box>
                </Alert>
            </Layout>
        </>
    )
}