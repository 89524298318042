import { Alert, AlertTitle, Button, Chip, Dialog, DialogContent, DialogTitle, Grid, Paper, Snackbar, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { format, parse } from "date-fns";
import { Fragment, useContext, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import MediaGrid from "../../components/MediaGrid";
import ScrollComponent from "../../components/ScrollComponent";
import UploadButton from "../../components/VoucherUploader";
import { GlobalContext } from "../../contexts/GlobalContext";
import BackToTop from "../../components/BackToTop";
import CheckinButton from "../../components/CheckinButton";
import ErrorMessage from "../../components/ErrorMessage";
import { FilterDataContext } from "../../contexts/FilterDataContext";
import { LoadingButton } from "@mui/lab";



export default function FilteredCheckins(){

    const navigate = useNavigate();

    const { filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, } = useContext(FilterDataContext);
    
    const { url, user, token,  } = useContext(GlobalContext);

    const title = "Filtered Check-Ins";

    const isMobile = useMediaQuery('(max-width:600px)');


    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/admin/visits?page=' + page, {
            user: user,
            buyer: filterDataBuyer?.id ? filterDataBuyer?.id : "",
            supplier: filterDataSupplier?.id ? filterDataSupplier?.id : "",
            from_date: filterDataFromDate,
            to_date: filterDataToDate,
            status: filterDataStatus === "allData" ? "" : filterDataStatus.toString(),
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    } 
        
    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'filteredCheckins',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const currentTab = { 
        name: "Checkins", 
        status: { 99: "All Data", 0: "Upcoming", 1: "Checked-in", 2: "Ordered", 3: "Cancelled", 4: "Not Ordered", 5: "Not Visited", },
    };

    // console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus,})

    

    const Item = ({data}) => {
        
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 2 }}>
                {
                    filterDataStatus === 'allData' && 
                    <Box sx={{py:1}}>
                        <Chip label={data?.status_label} />
                    </Box>
                }
                <Grid container sx={{borderTop: 1,borderColor: 'divider'}}>
                    <Grid item xs={6}  sx={{ borderBottom: 1, borderRight: 1,  borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p">Buyer</Typography>
                        <Typography variant="body" component="p">{data?.buyer_name}{!!data?.buyer_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.buyer_city_details?.name}</span>}</Typography>
                        {!!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>}
                    </Grid>
                    <Grid item xs={6}  sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p">Supplier</Typography>
                        <Typography variant="body" component="p">{data?.seller_name}{!!data?.seller_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.seller_city_details?.name}</span>}</Typography>
                        {!!data?.seller_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.seller_msme} /></Box>}
                    </Grid>
                    <Grid item xs={12} sx={{borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{ (data?.status_label === "Upcoming" || data?.status_label === "Checked-In" || data?.status == 1 || data?.status == 2) ? "Plan Date" : "Date"}</Typography>
                        <Typography variant="body" component="p">{!!data.date ? format(parse(data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy HH:mm:ss') : ''}</Typography>
                    </Grid>
                    {data?.status_label === "Checked-In" &&
                        <Grid item xs={12} sx={{borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Check-in Date</Typography>
                            <Typography variant="body" component="p">{!!data?.checkin_on ? format(new Date(data?.checkin_on), 'dd MMM yyyy HH:mm:ss') :"-"}</Typography>
                        </Grid>
                    }
                    <Grid item xs={12} sx={{borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{filterDataStatus == 2 ? "Check-in Remarks" : "Remarks"}</Typography>
                        <Typography variant="body" component="p">{data.remarks ? data.remarks : '-'}</Typography>
                    </Grid>

                    {
                        data?.order_details && data?.order_details !== null ? 
                        <>
                        {
                                data.order_details?.remarks ? 
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Order Remarks</Typography>
                                    <Typography variant="body" component="p">{ data.order_details.remarks }</Typography>
                                </Grid> 
                                :
                                <></>
                        }
                        {data?.order_details?.images &&
                            <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                <MediaGrid dir="orders" images={data?.order_details?.images} />
                            </Grid>
                        }
                        <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                            {data?.order_details?.show_button ?
                                <Button variant="contained" fullWidth  
                                    onClick={() => { if(data?.status_label === "Ordered") {
                                        navigate("/orderview/" + data?.order_details.id) 
                                    }}}
                                >
                                    View Order
                                </Button>
                                 :
                                <Alert severity="warning">This order data is being processed</Alert>
                            }
                        </Grid>
                        </> 
                        : 
                        <>
                        { data?.entry_details && data?.entry_details !== null ? 
                            <>
                            {
                                data.entry_details?.remarks ? 
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Order Remarks</Typography>
                                    <Typography variant="body" component="p">{ data.entry_details.remarks }</Typography>
                                </Grid> : <></>
                            }
                            {data?.entry_details.images &&
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <MediaGrid dir="orders" images={data?.entry_details.images} />
                                </Grid>
                             }
                            <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                <Alert severity="warning">This order data is being processed</Alert> 
                            </Grid>
                            </>
                            : 
                            <></> 
                        }
                        </>
                    }


                </Grid>
                {data?.status != 3 &&
                    <Box sx={{ borderTop: 1,  borderColor: 'divider',  display: 'flex', flexDirection: 'column', gap: 3, p: 1 }}>
                        <ItemAction status={data.status} id={data.id} order={data.order_id} buyer={data.buyer} seller={data.seller} />
                    </Box>
                }
            </Paper>
        )
    }

    function ItemAction (prop) {
        if(prop.status == 1) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "stretch", flexDirection: 'row', gap: 1,  }}>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="6"  id={prop.id} title="Mark Not Visited" />
                        </Box>                        
                        <Box sx={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center",}}>
                            <CheckinButton creator="2" id={prop.id} refetch={refetch} />
                        </Box> 
                    </Box>);
        } else if (prop.status == 2) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center',alignItems: "stretch", flexDirection: 'row', gap: 1 }}>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="5" id={prop.id} title="Mark Not Ordered" />
                        </Box>                        
                        <Box sx={{flex: 1,display: "flex", justifyContent: "center", alignItems: "center",}}>
                            <UploadButton refetch={refetch} buttonType="button" title="Order" seller={prop.seller} buyer={prop.buyer} link={prop.id} voucher_type="orders" />
                        </Box>
                    </Box>);
        } else if (prop.status == 4) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "stretch", flexDirection: 'row', gap: 1 }}>
                        <Box sx={{flex: 1}}> 
                            <MarkStatus status="6" id={prop.id} title="Mark Not Visited" /> 
                        </Box>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="5" id={prop.id} title="Mark Not Ordered" />
                        </Box>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="1" id={prop.id} title="Mark Pending" />
                        </Box>
                    </Box>);
        } else if (prop.status == 5) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 1 }}>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="1" id={prop.id} title="Mark Pending" />
                        </Box>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="4" id={prop.id} title="Mark Canceled" />
                        </Box>
                    </Box>);
        } else if (prop.status == 6) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 1 }}>
                        <Box sx={{flex: 1}}><MarkStatus status="1" id={prop.id} title="Mark Pending" /></Box>
                        <Box sx={{flex: 1}}><MarkStatus status="4" id={prop.id} title="Mark Canceled" /></Box>
                    </Box>);
        }
    }

    function MarkStatus ({status, id, title}) {

        const [open, setOpen] = useState(false);
        const [loading, setLoading] = useState(false);
        const [isError, setIsError] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");

        const handleOpen = () => {
            setOpen(true);
        }
    
        const handleClose = () => {
            setOpen(false);
            setLoading(false);
        }

        const clearError = () => {
            setIsError(false);
            setErrorMessage("");
        }

        const updateStatus = () => {
            setLoading(true);
            axios.post( url + 'api/admin/checkin/status', {
                user: user,
                id: id,
                status: status
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response.data.error == 0) {
                    handleClose();
                    refetch();
                } else {
                    setIsError(true);
                    setErrorMessage(response.data?.message);
                    handleClose();
                }
            })
            .catch(function (error) {
                setIsError(true);
                setErrorMessage(error?.message);
                console.log(error);
                handleClose();
            });
        }

        
        return (
            <Box sx={{width: "100%", height: "100%"}}>
                <Button variant="contained" size="small" sx={{height: "100%", fontSize: isMobile ? "10px" : "12px"}} fullWidth onClick={handleOpen}>{title}</Button>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle sx={{fontWeight: "600"}}>{title}</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                        <Typography variant="body2" >Do you really want to change the status of this visit?</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 3 }}>
                            <Button loading={loading} sx={{ fontSize: isMobile ? "10px" : "14px" }} size="small" variant="contained" color="error" fullWidth onClick={handleClose}>No</Button>
                            <LoadingButton loading={loading} sx={{ fontSize: isMobile ? "10px" : "14px" }} size="small" variant="contained" fullWidth onClick={updateStatus}>Yes</LoadingButton>
                        </Box>
                    </DialogContent>
                </Dialog>
                {isError &&
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isError} autoHideDuration={3000} onClose={clearError}>
                    <Alert severity="error" sx={{ width: '100%' }} >
                        {errorMessage}
                    </Alert>
                </Snackbar>
                }
            </Box>
        )
    }

    let isToday = false;

    if(filterDataFromDate && filterDataToDate){
        const isSameDay = format(new Date(filterDataFromDate), 'dd/MM/yyyy') === format(new Date(filterDataToDate), 'dd/MM/yyyy');
        if(isSameDay){
            isToday = format(new Date(), 'dd/MM/yyyy') === format(new Date(filterDataFromDate), 'dd/MM/yyyy');
        }
    }

    if(filterDataStatus === ""){
        navigate(-1, { replace: true })
        return null;
    }

    // console.log({data})

    return(
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
                <>            
                {  isLoading ? 
                <LoadingComponent loading={isLoading} />
                : 
                <Layout title={title} backToTop={true}>                
                    <Box>
                        <Alert severity="success" >
                            <AlertTitle sx={{fontSize: "0.5 em", fontWeight: "bold"}}> Results </AlertTitle>
                            <>
                                {filterDataBuyer &&  <Typography variant="subtitle2"><b>Buyer :</b> {filterDataBuyer?.name?.toUpperCase()}</Typography>}
                                {filterDataSupplier  && <Typography variant="subtitle2"><b>Supplier :</b> {filterDataSupplier?.name?.toUpperCase()}</Typography>}
                            </>
                           
                            {isToday ? 
                                <Typography variant="subtitle2">Today's Check-Ins (<b>{format(new Date(filterDataFromDate), 'dd/MM/yyyy')}</b>)</Typography> 
                                : 
                                (filterDataFromDate && filterDataToDate) && 
                                    <Typography variant="subtitle2"> <b>From:</b> {format(new Date(filterDataFromDate), 'dd/MM/yyyy')} <b>To:</b> {format(new Date(filterDataToDate), 'dd/MM/yyyy')}</Typography>
                            }    
                            <Box sx={{mt: 1}}>
                                { filterDataStatus == "allData" ? `Status: ${currentTab.status[99]}`: `Status: ${currentTab.status[filterDataStatus]}`}
                            </Box>
                        </Alert>                    
                    </Box>                
                    <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    <BackToTop alone={true} />
                </Layout>
                }
                </>
            } 
        </>       
    )
}