import React, { useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../components/Layout';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns';
import Button from '@mui/material/Button';
import { Chip, useMediaQuery } from '@mui/material';
import LoadingComponent from '../components/LoadingComponent';
import { useInfiniteQuery } from 'react-query';
import ErrorMessage from '../components/ErrorMessage';
import ScrollComponent from '../components/ScrollComponent';
import FilterForm from '../components/FilterForm';
import BackToTop from '../components/BackToTop';
import NewPlanButton from '../components/NewPlanButton';


export default function PlansPage() {
    let { status } = useParams();
    const navigate = useNavigate();

    const isMobile = useMediaQuery('(max-width:600px)');

    const { url, user, token } = useContext(GlobalContext);
    
    const title = "Plans";

    // console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate, filterDataToDate, filterDataStatus,})
    
    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/admin/plans?page=' + page, {
            status: pageStatus,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['plans', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch})

    const currentTab = { name: "Plans", status: { 99: "All Data", 0:"In-Progress", 1: "Completed", 2: "Cancelled",}};

    const Item = ({data}) => {

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Plan #</Typography>
                        <Typography variant="body" component="p">{data?.id}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Buyer</Typography>
                        <Typography variant="body" component="p">{data?.buyer_name}{ !! data?.buyer_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.buyer_city_details?.name}</span>}</Typography>
                            {
                                !!data?.msme  &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.msme} /></Box>
                            }
                    </Grid>
                    <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : '-'}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Return Date</Typography>
                        <Typography variant="body" component="p">{data.return_date ? format(parse(data.return_date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : '-'}</Typography>
                    </Grid>                    
                </Grid>
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1, display: "flex", gap:1}}>
                        <Box sx={{flex: 1}}>
                            <Button variant="contained" fullWidth={isMobile ? true :  false} onClick={() => {navigate("/plan/" + data?.id)}} sx={isMobile ? {} : {px: 5}} >View Plan</Button>
                        </Box>
                </Box>
            </Paper>
        )
    }

    return (
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={isLoading} />
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/plans/" + newStatus); }}>
                            <Tab label="In-Progress" />
                            <Tab label="Completed" />
                            <Tab label="Cancelled" />
                        </Tabs>                

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />

                    </Box>
                    
                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_plans"} />

                    <NewPlanButton 
                        refetch={refetch}
                        createdInAreas={[ 
                            {label : "Admin App"},
                            {label : "Add Button"},
                        ]} 
                    />

                    <BackToTop />
                    
                </Layout>
            }
            </>
            }
        </>
    )
}