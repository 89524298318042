import { useTheme } from "@emotion/react";
import { Fab } from "@mui/material"
import { IconChevronsUp } from "@tabler/icons-react";
import { useEffect, useState } from "react";

export default function BackToTop({alone=false}) {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setIsVisible(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const theme = useTheme();

    return (
        <>            
            {isVisible &&
                <Fab onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    })
                }} size={"small"} sx={{
                    backgroundColor: "#f0f4f6",
                    color: "#000",
                    opacity: 0.5,
                    position: "fixed",
                    bottom: (theme) => theme.spacing(alone ? 3 : 13),
                    right: (theme) => theme.spacing(3)
                }}>
                    <IconChevronsUp />
                </Fab>
            }            
        </>
    )
}