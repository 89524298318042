import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Fab from '@mui/material/Fab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { IconPlus } from '@tabler/icons-react';
import { GlobalContext } from '../contexts/GlobalContext'
import 'filepond/dist/filepond.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Snackbar, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import heic2any from 'heic2any';

registerPlugin(FilePondPluginFileRename)
registerPlugin(FilePondPluginFileValidateType);

function UploadButton(props) {
    const { url, user, token, masters  } = useContext(GlobalContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [date, setDate] = useState("")
    const [files, setFiles] = useState([])
    const [fileList, setFileList] = useState([])
    const [channel, setChannel] = useState("")
    const [remarks, setRemarks] = useState("")
    const [amount, setAmount] = useState(0)
    const [pond, setPond] = useState('')
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false)

    const [buyerOptions, setBuyerOptions] = useState([])
    const [selectedBuyer, setSelectedBuyer] = useState(null);

    const [supplierOptions, setSupplierOptions] = useState([])
    const [selectedSupplier, setSelectedSupplier] = useState(null);

    const [isError, setIsError] = useState(false);
    const [ errorMessage, setErrorMessage] = useState("");

    const [info, setInfo] = useState("");

    const isMobile = useMediaQuery('(max-width:600px)');

    const handleOpen = () => {
        setOpen(true);
    };    

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
        setBuyerOptions([])
        setSelectedBuyer(null);
        setSelectedSupplier(null);
        setSupplierOptions([]);
        setDate("");
        setChannel("");
        setAmount(0);
        setRemarks("");
        setFiles([]);
        setFileList([])
    };    
    
    const handleFile = () => {
        pond.browse();
    };

    const clearError = () => {
        setIsError(false);
        setErrorMessage("");
    }

    const fileRenameFunction = (file) => {
        if(file.extension == file.name) {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}.jpg`;
        } else {
            return `f_${Math.random().toString(36).slice(2, 7)}_${(+new Date).toString(36).slice(-5)}${file.extension}`;
        }
    };

    // console.log({buyer: props?.buyer, seller: props?.seller})

    const handleSubmit = (event) => {
        event.preventDefault();
        if(!!selectedBuyer?.id &&!!selectedSupplier?.id || !!props?.buyer && !!props.seller){
        setLoading(true);
        // var fileList = [];
        // files.forEach(function(f) {
        //     fileList.push({ file: f.filename, type: f.fileType, ext: f.fileExtension } );
        // })
          
        if((fileList.length == 0) && !remarks ) {
            // alert("Either enter remarks \n ( or ) \n Add one or more attachment to create entry");
            // return;
            setIsError(true)
            setErrorMessage("Either enter Remarks ( or ) Add one or more Attachment to create an entry");
            setLoading(false)
            return null
        } 
    
        axios.post( url + 'api/vouchers/add', {
            user: user,
            creator: 1,
            seller:  selectedSupplier?.id || props?.seller,
            buyer: selectedBuyer?.id || props?.buyer,
            date: date,
            channel: channel.id,
            remarks: remarks,
            amount: amount,
            link: props.link ? props.link : null,
            order_type: props.order_type != undefined ? props.order_type : null,
            files: JSON.stringify(fileList),
            voucher_type: props.voucher_type ? props.voucher_type : null,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            if(response.data.error == 0) {
                handleClose();
                props?.refetch ? props.refetch() : navigate(location.pathname);                
            } else {
                setIsError(true)
                setErrorMessage(response.data?.message);
                handleClose();
            }
        })
        .catch(function (error) {
            console.log(error);
            setIsError(true)
            setErrorMessage(error?.message);
            handleClose();
        })
    }
    };

    function CustomButton() {
        if(props.buttonType=="fab") {
            return (
                <Fab color="primary" aria-label="add" onClick={handleOpen} sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                  }}>
                    <IconPlus />
                </Fab>
            )
        } else if(props.buttonType=="card") {
            return (
                <Card sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }} onClick={handleOpen}>
                    { props.icon }
                    <Typography variant="body1" component="p">New {props.title}</Typography>
                </Card>
            )
        } else {
            return (
                <Button style={{height: "100%", fontSize: isMobile ? "10px" : "14px"}} size="small" variant="contained" fullWidth onClick={handleOpen} >Upload {props.title}</Button>
            )
        }
    }

    // console.log({selectedBuyer, selectedSupplier})

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                search_term: query,
                supplier: selectedSupplier?.id || "",                
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyerOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const fetchSuppliers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "supplier",
                search_term: query,
                buyer: selectedBuyer?.id || "",
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setSupplierOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    useEffect(() => {

        // console.log({selectedBuyer, selectedSupplier})

        const fetchInfo = () => {
            axios.post(url + 'api/check/latest-entry', {
                buyer: selectedBuyer?.id,
                seller: selectedSupplier?.id, 
                entry_type : "orders",
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then((response) => {
                // console.log({response})
                setInfo(response?.data)
            })
            .catch((error) => {
                console.log({error});
                setInfo(null)
            })
        }

        if(!!selectedBuyer?.id &&!!selectedSupplier?.id){
            fetchInfo();
        }else{
            setInfo(null)
        }
        
    }, [selectedBuyer, selectedSupplier])

    function convertHEICToJPG(file) {
        return heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.6
        });
    }
    
    return (
        <Box sx={{width: "100%", height: "100%"}}>
            <CustomButton></CustomButton>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Upload {props.title}</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <form onSubmit={handleSubmit}>

                        { !!info && !!Object.keys(info).length && 
                            <Alert icon={false} severity="info" sx={{mb: 2}}>
                                <Typography variant="caption" component="p" sx={{fontSize: "0.8em"}}> Last order entry for this Buyer and Supplier occurred on <span style={{fontWeight: "bold"}}>{format(new Date(info?.created_at), 'dd MMM yyyy HH:mm:ss')}</span> by <span style={{fontWeight: "bold"}}>{info?.creator_name || info?.creator_details?.name}</span>.</Typography>
                            </Alert>
                        }

                        {props.buttonType == "card" && props.voucher_type == "orders" && 
                            <>
                                <InputLabel shrink>Buyer </InputLabel>
                                <Autocomplete sx={{ mb: 2 }}
                                    size="small"
                                    options={ buyerOptions }
                                    getOptionLabel={(option) => option?.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                        {option.name}
                                        </Box>
                                    )}
                                    onInputChange={(event, newInputValue) => { fetchBuyers(newInputValue) }}
                                    value={selectedBuyer}
                                    onChange={(event, newValue) => {
                                        if (newValue === null) {
                                            !!selectedSupplier?.id ? setSupplierOptions([selectedSupplier]) : setSupplierOptions([])
                                            setBuyerOptions([])
                                            setSelectedBuyer(newValue)      
                                        }else{
                                            setSelectedBuyer(newValue)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    // isOptionEqualToValue = {(option) => {
                                    //     console.log({option})
                                    //     return option.name === selectedBuyer.name
                                    // } }
                                    isOptionEqualToValue ={(option, value) => {if(!value){return true}else{ return value?.id == option?.id}} }
                                />
                            </>
                        }

                        {props.buttonType == "card" && props.voucher_type == "orders" && 
                            <>
                                <InputLabel shrink>Supplier </InputLabel>
                                <Autocomplete sx={{ mb: 2 }}
                                    size="small"
                                    options={ supplierOptions }
                                    getOptionLabel={(option) => option?.name }
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                        {option.name}
                                        </Box>
                                    )}
                                    onInputChange={(event, newInputValue) => { fetchSuppliers(newInputValue) }}
                                    value={selectedSupplier}
                                    onChange={(event, newValue) => {
                                        if (newValue === null) {
                                            !!selectedBuyer?.id ? setBuyerOptions([selectedBuyer]) : setBuyerOptions([])
                                            setSelectedSupplier(newValue)
                                            setSupplierOptions([])                                            
                                        }else{
                                            setSelectedSupplier(newValue)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    // isOptionEqualToValue = {(option) => option.name === selectedSupplier.name }
                                    isOptionEqualToValue ={(option, value) => {if(!value){return true}else{ return value?.id == option?.id}} }
                                />
                            </>
                        }

                        <>
                            <InputLabel shrink>Date</InputLabel>
                            <TextField sx={{ mb: 2 }} fullWidth required={true} type="date" size="small"
                                value={date}
                                onChange={event => {
                                    const { value } = event.target;
                                    setDate(value);
                            }} />
                        </>

                        { props.voucher_type != "payments" &&
                            <>
                                <InputLabel shrink>Channel</InputLabel>
                                <Autocomplete sx={{ mb: 2 }}
                                    size="small"
                                    options={ masters.channels }
                                    getOptionLabel={(option) => option.name ? option.name : '' }                                    
                                    value={channel}
                                    onChange={(event, newValue) => {
                                        setChannel(newValue)
                                    }}
                                    isOptionEqualToValue={(option, value) => { 
                                        if(!value){
                                            return true
                                        }else{
                                            return option?.name == value?.name
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} required={true} />}
                                />
                            </>
                        }

                        { props.order_type != 4 ?
                            <Box>
                                <InputLabel shrink>Amount</InputLabel>
                                <TextField sx={{ mb: 2 }} fullWidth type="number" step="any" size="small"
                                    value={amount}
                                    onChange={event => setAmount(event.target.value) } />
                            </Box>
                            :
                            <></>
                        }
                        
                        <>
                            <InputLabel shrink>{ props.voucher_type == "payments" ? "Narration" : "Remarks" }</InputLabel>
                            <TextField sx={{ mb: 2 }} fullWidth size="small"
                                value={remarks}
                                onChange={event => {
                                    const { value } = event.target;
                                    setRemarks(value);
                                }} 
                            />
                        </>

                        <InputLabel shrink>Attachments</InputLabel>
                        <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1, mb: 2 }}>
                            <Button sx={{ fontSize: isMobile ? "10px" : "14px" }} variant="contained" size="medium" onClick={handleFile}>File</Button>
                        </Box>
                        <FilePond
                            acceptedFileTypes={["application/pdf", "image/png", "image/jpg", "image/jpeg", "image/heic", "" ]}
                            ref={ref => ( setPond(ref) )}
                            credits={false}
                            files={files}
                            onupdatefiles={setFiles}
                            fileRenameFunction={ file => ( fileRenameFunction(file) )}
                            allowMultiple={true}
                            // server={ url + "api/pond/process"}
                            server={{
                                process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                    console.log({file});
                                    try {
                                        let fn = file.name.toLowerCase()
                                        // Check if the file type is HEIC
                                        if (fn.indexOf('.heic') !== -1) {
                                            // Convert HEIC to JPG
                                            const convertedBlob = await convertHEICToJPG(file);
                                            // Create a new file object from the Blob
                                            const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), {
                                                type: "image/jpeg",
                                            });
                                            file = newFile; // Update the file reference
                                        }
                        
                                        // Now upload the file (either original or converted)
                                        // Example using fetch API
                                        const formData = new FormData();
                                        formData.append(fieldName, file, file.name);
                                        const response = await fetch(url + "api/pond/process", {
                                            method: 'POST',
                                            body: formData
                                        });
                        
                                        if (response.ok) {
                                            const data = await response.json();
                                            load(data.name);
                                            let currentFile = { file: data.name, type: data.type, ext: data.ext };
                                            setFileList(currentFiles => [...currentFiles, currentFile]);
                                        } else {
                                            error('Could not upload file');
                                        }
                                    } catch (err) {
                                        error(err.message);
                                    }
                                }
                            }}
                            name="files" /* sets the file input name, it's filepond by default */
                            labelIdle='No files selected'
                            onaddfile={(error, file) => { 
                                if(!error){
                                    setLoading(true)
                                }
                            }}  
                            onprocessfiles={() => setLoading(false)}   
                            onprocessfilerevert={() => setLoading(true)}   
                            onremovefile={() => setLoading(false)}
                            onerror={(error, file) => {console.log({error, file})}}
                        />
                    
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton sx={{ fontSize: isMobile ? "10px" : "14px" }} size="small" type="submit" loading={loading} variant="contained">Save</LoadingButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            {isError &&
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isError} autoHideDuration={3000} onClose={clearError}>
                    <Alert onClose={clearError} severity="error" variant='filled' sx={{ width: '100%' }} >
                        {errorMessage}
                    </Alert>
                </Snackbar>
            }
        </Box>
    )
}
export default UploadButton;