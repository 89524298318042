import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../components/Layout';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns';
import MediaGrid from '../components/MediaGrid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import FilterButton from '../components/FilterButton';
import LoadingComponent from '../components/LoadingComponent';
import FilterView from '../components/FilterView';
import { Chip, useMediaQuery } from '@mui/material';


function CustomOrders() {
    let { status } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, customer, user, token, portal } = useContext(GlobalContext);
    const [buyer, setBuyer] = useState("");
    const [supplier, setSupplier] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [loading, setLoading] = useState(true);
 
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);

    const [filterApplied, setFilterApplied] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const [currentPage1, setCurrentPage1] = useState(1);
    const [hasMore1, setHasMore1] = useState(true);

    const [currentStatus, setCurrentStatus] = useState(0); // selected status of the items in select box 
    const currentTab = { name: "CustomOrders", status: {  1: "Ordered", 2: "Completed", 99: "All Data",}};

    const isMobile = useMediaQuery('(max-width:600px)')

    const handleChange = (event, newStatus) => {
        setLoading(true)
        setItems([]);
        setHasMore(true);
        setCurrentPage(1);
        navigate("/customorders/" + newStatus)
    };


    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(1);
        fetchData(1);
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Custom Order List");
        }
    }, [ status ]);

    useEffect(() => {
        setLoading(true)
        setFilteredItems([]);
        setHasMore1(true);
        setCurrentPage1(1);
        filterApplied  && fetchFilteredData(1)
        setLoading(false)
    }, [ buyer, supplier, fromDate, toDate ])

    const fetchData = (page) => {
        axios.post( url + 'api/admin/customorders?page=' + page, {
            buyer: buyer?.id,
            supplier: supplier?.id,
            from_date: fromDate,
            to_date: toDate,
            status: status,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setLoading(true)
            setItems((prev) => page != 1 ? [...prev, ...response.data.data] : response.data.data);
            setCurrentPage(parseInt(response.data.current_page));
            if(response.data.current_page == response.data.last_page) {
                setHasMore(false);
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(()=>setLoading(false));
    }

    const fetchFilteredData = (page) => {
        axios.post( url + 'api/admin/customorders?page=' + page, {
            buyer: buyer?.id,
            supplier: supplier?.id,
            from_date: fromDate,
            to_date: toDate,
            status: currentStatus !== 99 ?  currentStatus : "" ,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setLoading(true)
            setFilteredItems((prev) => [...prev, ...response.data.data]);
            setCurrentPage1(parseInt(response.data.current_page));
            setHasMore1(response.data.current_page !== response.data.last_page);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function handleFilterClose(){
        setLoading(true)
        setFilterApplied(false);
        setBuyer("")
        setSupplier("") 
        setFromDate("") 
        setToDate("")
        setCurrentStatus(0)
        setLoading(false)
    }

    const CustomOrderActions = (prop) => {
        const [open, setOpen] = useState(false)
        const [open2, setOpen2] = useState(false)
        const [reason, setReason] = useState("")

        const handleOpen = () => {
            setOpen(true);
        }
    
        const handleOpen2 = () => {
            setOpen2(true);
        }

        const handleClose = () => {
            setOpen(false);
        }
    
        const handleClose2 = () => {
            setOpen2(false);
        }
        
        const handleApproval = (event) => {
            event.preventDefault();
            axios.post( url + 'api/admin/customorders/approval', {
                customer: customer,
                contact: contact,
                phone: phone,
                id: prop.id,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response.data.error == 0) {
                    setOpen(false);
                    // navigate(0);
                    document.location.reload();
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }     

        const handleRejection = (event) => {
            event.preventDefault();
            axios.post( url + 'api/admin/customorders/rejection', {
                customer: customer,
                contact: contact,
                phone: phone,
                id: prop.id,
                reason: reason,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response.data.error == 0) {
                    setOpen(false);
                    // navigate(0);
                    document.location.reload();
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }    

        return (
        <>
            { portal == "supplier" && prop.approval == 0 &&
                <Grid container>
                    <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Button variant="contained" size="large" color="success" onClick={handleOpen2} fullWidth>Accept</Button>
                    </Grid>
                    <Grid item xs={6} sx={{ px: 1, py:1 }}>
                        <Button variant="contained" size="large" color="error" onClick={handleOpen} fullWidth>Reject</Button>
                    </Grid>
                </Grid>
            }

            {open &&
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Reject Order</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                    <DialogContentText>
                        To reject this custom order, please enter the reason for rejection.
                    </DialogContentText>
                    <form onSubmit={handleRejection}>
                        <InputLabel shrink>Reason</InputLabel>
                        <TextField sx={{ mb: 2 }} fullWidth required 
                            value={reason}
                            onChange={event => {
                                const { value } = event.target;
                                setReason(value);
                            }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <Button type="submit" variant="contained">Reject</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>}

            {open2 && 
            <Dialog onClose={handleClose2} open={open2}>
                <DialogTitle>Accept Order</DialogTitle>
                <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
                    <Typography>Do you accept this order?</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
                        <Button type="submit" variant="outlined" onClick={handleClose2}>Close</Button>
                        <Button type="submit" variant="contained"  onClick={handleApproval}>Accept</Button>
                    </Box>
                </DialogContent>
            </Dialog>}
        </>
        )
    }
    
    const Item = (prop) => {
        let reason = '';
        if(prop.data?.approval == 2 && prop.data?.approval_log) {
            reason = JSON.parse(prop.data.approval_log)[0]?.reason || "-";
        }

        function redirectOnClick() {   
                if (prop.data.entry_id && prop.data.order_type == 4) {
                    navigate("/order/" + prop.data.id) 
                }else{
                    navigate("/order/" + prop.data.voucher_id) 
                }
        }

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                { currentStatus === 99 && prop.data?.status_label && 
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}> <Chip label={prop.data.status_label}  /></Box>
                }
                <Box>
                    <Box sx={{display: "flex", py:1}}>
                    <Box sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 2, py:1, flex: 1 }}>
                        <Typography variant="overline" component="p">Buyer</Typography>
                        <Typography variant="h6" component="h6">{prop.data.buyer_name}</Typography>
                    </Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1, flex: 1 }}>
                        <Typography variant="overline" component="p">Supplier</Typography>
                        <Typography variant="h6" component="h6">{prop.data.seller_name}</Typography>
                    </Box>
                    </Box>
                    <Grid container>
                        {
                            !(prop.data?.status == 1 && prop.data?.approval == 0) ?
                            <>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Order #</Typography>
                                    <Typography variant="body" component="p">{prop.data.voucher_no ? prop.data.voucher_no : '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Date</Typography>
                                    <Typography variant="body" component="p">{prop.data.date ? format(parse(prop.data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Total</Typography>
                                    {prop.data?.total ?  <Typography variant="body" component="p">&#8377; {prop.data?.total} </Typography> : <Typography variant="body" component="p">-</Typography> }
                                </Grid>
                            </>
                            :
                            <></>
                        }

                        { prop.data?.status == 1 && prop.data?.approval == 0 &&
                            <Grid item xs={12} sx={{ px: 1, py:1, borderBottom: 1, borderColor: 'divider', }}>
                                <Typography variant="overline" component="p">Date</Typography>
                                <Typography variant="body" component="p">{prop.data.date ? format(parse(prop.data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                            </Grid>
                        }
                        <Grid item xs={12} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{prop.data.remarks ? prop.data.remarks : '-'}</Typography>
                        </Grid>                        
                        {
                           prop.data?.status == 1 && prop.data?.approval == 2 ?
                            <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider',px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Rejection Reason</Typography>
                                <Typography variant="body" component="p">{reason ? reason : '-'}</Typography>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                </Box>
                
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={prop.data.images} />
                </Box>
                
                { status == 0 &&
                    <CustomOrderActions approval={prop.data.approval} id={prop.data.id} />
                }
                {/* status == 1 &&
                    <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 2, py: 2 }}>
                        <Void id={prop.data.id} type={ 'voucher' } voided={prop.data.status != 3 ? 0 : 1 } />
                    </Box>
                */}

                <Box sx={{borderTop: 1, borderColor: 'divider', p:1, mt:1, display: "flex", gap:1}}>
                    {(prop.data.status == 6 || prop.data.status == 2) &&
                    <Box sx={{flex: 1}}>
                        <Button variant="contained" fullWidth onClick={()=>{navigate("/orderview/" + prop.data?.id)}} >View Order</Button>
                    </Box>}
                    <Box sx={{flex: 1}}>
                            <Button variant="contained" fullWidth sx={isMobile ? {} : {px: 10}} onClick={redirectOnClick}>Order Chat</Button>
                    </Box>
                </Box>

            </Paper>
        )
    }

    return (
        <Box>
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Custom Orders" >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={handleChange}>
                        <Tab label="Approval" />
                        <Tab label="Ordered" />
                        <Tab label="Completed" />
                        <Tab label="Rejected" />
                    </Tabs>

                    <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={()=>fetchData(currentPage+1)} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                    {items?.map((row, index) => (
                        <Item key={index} data={row}></Item>
                    ))}
                    </InfiniteScroll>
                </Box>
                <FilterButton 
                    setBuyer={setBuyer} setSupplier={setSupplier} 
                    setFromDate={setFromDate} setToDate={setToDate}
                    setFilterApplied={setFilterApplied}
                    currentTab={currentTab}
                    currentStatus={currentStatus}
                    setCurrentStatus={setCurrentStatus}
                />
            </Layout>
        }
        </>
        <FilterView 
        filterApplied={filterApplied} setFilterApplied={setFilterApplied} currentPage1={currentPage1}
        handleFilterClose={handleFilterClose} filteredItems={filteredItems} 
        fetchFilteredData={fetchFilteredData} hasMore={hasMore} Item={Item}
        buyer={buyer} supplier={supplier} fromDate={fromDate} toDate={toDate} loading={loading} 
        currentTab={currentTab} currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus} hasMore1={hasMore1} />
        </Box>
    );
}

export default CustomOrders;
