import React, { useState, useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../components/Layout';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import MediaGrid from '../components/MediaGrid';
import { Chip, DialogContentText, InputLabel, TextField, useMediaQuery } from '@mui/material';
import LoadingComponent from '../components/LoadingComponent';
import { useInfiniteQuery } from 'react-query';
import ErrorMessage from '../components/ErrorMessage';
import ScrollComponent from '../components/ScrollComponent';
import FilterForm from '../components/FilterForm';
import BackToTop from '../components/BackToTop';


export default function CustomOrdersPage() {
    let { status } = useParams();
    const navigate = useNavigate();

    const isMobile = useMediaQuery('(max-width:600px)');

    const { url, user, token } = useContext(GlobalContext);

    
    const title = "Custom Orders";

    // console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate, filterDataToDate, filterDataStatus,})
    
    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/admin/customorders?page=' + page, {
            status: pageStatus,
            user: user,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['checkins', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch})

    const currentTab = { name: "CustomOrders", status: {  1: "Ordered", 2: "Completed", 99: "All Data",}};

    const Item = ({data}) => {
        let reason = '';
        if(data?.approval == 2 && data?.approval_log) {
            reason = JSON.parse(data.approval_log)[0]?.reason || "-";
        }

        function redirectOnClick() {   
                if (data.entry_id && data.order_type == 4) {
                    navigate("/order/" + data.id) 
                }else{
                    navigate("/order/" + data.voucher_id) 
                }
        }

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box>
                    <Box sx={{display: "flex", py:1}}>
                        <Box sx={{ borderBottom: 1, borderRight: 1, borderColor: 'divider', px: 2, py:1, flex: 1 }}>
                            <Typography variant="overline" component="p">Buyer</Typography>
                            <Typography variant="body" component="p">{data?.buyer_name}{!!data?.buyer_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.buyer_city_details?.name}</span>}</Typography>
                            {
                                !!data?.buyer_msme  &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                            }
                        </Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1, flex: 1 }}>
                            <Typography variant="overline" component="p">Supplier</Typography>
                            <Typography variant="body" component="p">{data?.seller_name}{!!data?.seller_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.seller_city_details?.name}</span>}</Typography>
                            {
                                !!data?.seller_msme  &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.seller_msme} /></Box>
                            }
                        </Box>
                    </Box>
                    <Grid container>
                        {
                            !(data?.status == 1 && data?.approval == 0) ?
                            <>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Order #</Typography>
                                    <Typography variant="body" component="p">{data.voucher_no ? data.voucher_no : '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Date</Typography>
                                    <Typography variant="body" component="p">{!!data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Total</Typography>
                                    {data?.total ?  <Typography variant="body" component="p">&#8377; {data?.total} </Typography> : <Typography variant="body" component="p">-</Typography> }
                                </Grid>
                            </>
                            :
                            <Grid item xs={12} sx={{ px: 1, py:1, borderBottom: 1, borderColor: 'divider', }}>
                                <Typography variant="overline" component="p">Date</Typography>
                                <Typography variant="body" component="p">{!!data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                            </Grid>
                        }

                        <Grid item xs={12} sx={{ px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Remarks</Typography>
                            <Typography variant="body" component="p">{data.remarks ? data.remarks : '-'}</Typography>
                        </Grid>                        
                        {
                           data?.status == 1 && data?.approval == 2 ?
                            <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider',px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Rejection Reason</Typography>
                                <Typography variant="body" component="p">{reason ? reason : '-'}</Typography>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                </Box>
                
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={data.images} />
                </Box>
                
                {/* { status == 0 &&
                    <CustomOrderActions approval={data.approval} id={data.id} />
                } */}

                <Box sx={{borderTop: 1, borderColor: 'divider', p:1, mt:1, display: "flex", gap:1}}>
                    {
                        (data.status == 6 || data.status == 2) ?
                        <Box sx={{display: "flex", gap: 1, flexDirection:"row", flex: 1}}>
                            <Box sx={{flex: 1}}>
                                <Button variant="contained" fullWidth onClick={()=>{navigate("/orderview/" + data?.id)}} >View Order</Button>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <Button variant="contained" fullWidth onClick={redirectOnClick}>Order Chat</Button>
                            </Box>
                        </Box>
                        :                        
                        <Box sx={{flex: 1}}>
                            <Button variant="contained" fullWidth={isMobile ? true : false} onClick={redirectOnClick}>Order Chat</Button>
                        </Box>
                    }
                </Box>

            </Paper>
        )
    }   


    // const CustomOrderActions = (prop) => {

    //     const [open, setOpen] = useState(false)
    //     const [open2, setOpen2] = useState(false)
    //     const [reason, setReason] = useState("")

    //     const handleOpen = () => {
    //         setOpen(true);
    //     }
    
    //     const handleOpen2 = () => {
    //         setOpen2(true);
    //     }

    //     const handleClose = () => {
    //         setOpen(false);
    //     }
    
    //     const handleClose2 = () => {
    //         setOpen2(false);
    //     }
        
    //     const handleApproval = (event) => {
    //         event.preventDefault();
    //         axios.post( url + 'api/admin/customorders/approval', {
    //             customer: customer,
    //             contact: contact,
    //             phone: phone,
    //             id: prop.id,
    //         }, { headers: { Authorization: `Bearer ${token}` }})
    //         .then(function (response) {
    //             if(response.data.error == 0) {
    //                 setOpen(false);
    //                 refetch();
    //             } else {
    //                 alert(response.data.message);
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    //     }     

    //     const handleRejection = (event) => {
    //         event.preventDefault();
    //         axios.post( url + 'api/admin/customorders/rejection', {
    //             customer: customer,
    //             contact: contact,
    //             phone: phone,
    //             id: prop.id,
    //             reason: reason,
    //         }, { headers: { Authorization: `Bearer ${token}` }})
    //         .then(function (response) {
    //             if(response.data.error == 0) {
    //                 setOpen(false);
    //                 refetch();
    //             } else {
    //                 alert(response.data.message);
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    //     }    

    //     return (
    //     <>
    //         { prop.approval == 0 &&
    //             <Grid container>
    //                 <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
    //                     <Button variant="contained" size="large" color="success" onClick={handleOpen2} fullWidth>Accept</Button>
    //                 </Grid>
    //                 <Grid item xs={6} sx={{ px: 1, py:1 }}>
    //                     <Button variant="contained" size="large" color="error" onClick={handleOpen} fullWidth>Reject</Button>
    //                 </Grid>
    //             </Grid>
    //         }

    //         {open &&
    //         <Dialog onClose={handleClose} open={open}>
    //             <DialogTitle>Reject Order</DialogTitle>
    //             <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
    //                 <DialogContentText>
    //                     To reject this custom order, please enter the reason for rejection.
    //                 </DialogContentText>
    //                 <form onSubmit={handleRejection}>
    //                     <InputLabel shrink>Reason</InputLabel>
    //                     <TextField sx={{ mb: 2 }} fullWidth required 
    //                         value={reason}
    //                         onChange={event => {
    //                             const { value } = event.target;
    //                             setReason(value);
    //                         }} />
    //                     <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
    //                         <Button type="submit" variant="contained">Reject</Button>
    //                     </Box>
    //                 </form>
    //             </DialogContent>
    //         </Dialog>}

    //         {open2 && 
    //         <Dialog onClose={handleClose2} open={open2}>
    //             <DialogTitle>Accept Order</DialogTitle>
    //             <DialogContent sx={{ width: '80vw', maxWidth: '500px' }}>
    //                 <Typography>Do you accept this order?</Typography>
    //                 <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
    //                     <Button type="submit" variant="outlined" onClick={handleClose2}>Close</Button>
    //                     <Button type="submit" variant="contained"  onClick={handleApproval}>Accept</Button>
    //                 </Box>
    //             </DialogContent>
    //         </Dialog>}
    //     </>
    //     )
    // }

    

    return (
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={isLoading} />
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/customorders/" + newStatus); }}>
                            <Tab label="Approval" />
                            <Tab label="Ordered" />
                            <Tab label="Completed" />
                            <Tab label="Rejected" />
                        </Tabs>            

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />

                    </Box>
                    
                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_custom_orders"} />

                    <BackToTop />
                    
                </Layout>
            }
            </>
            }
        </>
    )
}