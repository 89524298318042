import React, { useContext, useState } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IconPlus } from '@tabler/icons-react';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Alert, Snackbar } from '@mui/material';

function NewCheckinButton(props) {
    const { url, user, token  } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [ledger, setLedger] = useState("")
    const [date, setDate] = useState("")
    const [remarks, setRemarks] = useState("")

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);

    const [buyer, setBuyer] = useState(null)
    const [supplier, setSupplier] = useState(null)
    const [buyers, setBuyers] = useState([])
    const [suppliers, setSuppliers] = useState([])

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setBuyer(null);
        setSupplier(null);
        setRemarks("");
        setDate("");
        setOpen(false);
        setLoading(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post( url + 'api/checkin/add', {
            user: user,
            date: date,
            remarks: remarks,
            seller: supplier.id,
            buyer: buyer.id,
            plan: props.plan,
            creator: 1,
            created_in_areas : JSON.stringify(props.createdInAreas),
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            // console.log("response", response);
            if(response.data.error == 0) {
                setLoading(false);
                props?.refetch ? props.refetch() : document.location.reload()
                handleClose()
            }else if(response.data.error == 1){
                setOpenAlert(true)
                handleClose()
                setLoading(false);
            }
        })
        .catch(function (error) {
            setLoading(false);
            console.log({error})
        });
    }

    // console.log({buyer, supplier})

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                search_term: query,
                supplier: !!supplier?.id ? supplier?.id : ""
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyers(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const fetchSuppliers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "supplier",
                search_term: query,
                buyer: !!buyer?.id ? buyer?.id : "",
                query: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setSuppliers(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const [openAlert, setOpenAlert] = useState(false);

    const handleAlertClose = () => {
        setOpenAlert(false)
    }

    return (
        <>        
            <>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>New {props?.title}</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                        <form onSubmit={handleSubmit}>
                            <InputLabel shrink>Date & Time</InputLabel>
                            <TextField sx={{ mb: 2 }} fullWidth required={true} type="datetime-local" size="small"
                                value={date}
                                onChange={event => {
                                    const { value } = event.target;
                                    setDate(value);
                                }} 
                                InputProps={{
                                    inputProps: {
                                        max: `${format(new Date(), "yyyy-MM-dd")}T23:59`,
                                    },
                                }}
                                />

                            <InputLabel shrink>Buyer</InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                size="small"
                                options={ buyers }
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(event, newInputValue) => { fetchBuyers(newInputValue) }}
                                value={buyer}
                                onChange={(event, newValue) => {
                                    if (newValue === null) {
                                        !!supplier?.id ? setSuppliers([supplier]) : setSuppliers([])
                                        setBuyer(newValue)
                                        setBuyers([])
                                    }else{
                                        setBuyer(newValue)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} required={true} />}
                                isOptionEqualToValue={(option, value) => { 
                                    if(!value){
                                        return true
                                    }else{
                                        return option?.id == value?.id
                                    }
                                }}
                            />

                            <InputLabel shrink>Supplier</InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                size="small"
                                options={ suppliers }
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(event, newInputValue) => { fetchSuppliers(newInputValue) }}
                                value={supplier}
                                onChange={(event, newValue) => {
                                    if (newValue === null) {
                                        !!buyer?.id ? setBuyers([buyer]) :setBuyers([])
                                        setSupplier(newValue);                                        
                                        setSuppliers([])
                                    }else{
                                        setSupplier(newValue)
                                    }
                                    
                                }}
                                renderInput={(params) => <TextField {...params} required={true} />}
                                isOptionEqualToValue={(option, value) => { 
                                    if(!value){
                                        return true
                                    }else{
                                        return option?.id == value?.id
                                    }
                                }}
                            />
                            
                            <InputLabel shrink>Remarks</InputLabel>
                            <TextField sx={{ mb: 2 }} fullWidth required={false} size="small"
                                value={remarks}
                                onChange={event => {
                                    const { value } = event.target;
                                    setRemarks(value);
                                }} 
                                rows={4}
                                multiline={true}
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                                <LoadingButton size="small" type="submit" loading={loading} variant="contained">Save</LoadingButton>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
                {props.buttonType=="card" ?
                    <Card sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }} onClick={handleOpen}>
                        { props.icon }
                        <Typography variant="body1" component="p">New Check-In</Typography>
                    </Card>
                    :
                    <Fab color="primary" aria-label="add" onClick={handleOpen} 
                        sx={{ 
                            position: "fixed", 
                            bottom: (theme) => theme.spacing(2), 
                            right: (theme) => theme.spacing(2) 
                        }}
                        size={"medium"}
                    >
                        <IconPlus />
                    </Fab>
                }
            </>
            {openAlert &&
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleAlertClose} severity="error" variant="filled"  sx={{ width: '100%' }}>
                    There is already another Check-in or Plan scheduled for this Date, Buyer and Supplier!
                </Alert>
            </Snackbar>} 
        </>
    )
}
export default NewCheckinButton;