import React, { useContext, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { IconLayoutGrid, IconArrowNarrowLeft, IconHome } from '@tabler/icons-react';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";

function Layout(props) {
    const { portal  } = useContext(GlobalContext);
    const navigate = useNavigate();
    return (
        <Box sx={{ flexGrow: 1, paddingBottom: 'calc(env(safe-area-inset-bottom) + 10px)' }}>
            <AppBar position="sticky" color="secondary" sx={{ backgroundImage: "none"}}>
                <Toolbar>
                    {  
                        !props.hasOwnProperty('hideNavigation') &&
                        <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => { navigate(-1) }} >
                            <IconArrowNarrowLeft />
                        </IconButton>
                    }
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', fontSize: "1rem" }}>{props.title}</Typography>
                    {  
                        !props.hasOwnProperty('hideNavigation') &&
                        <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => { return navigate("/dashboard") }} >
                            <IconHome />
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>
            {  props.hasOwnProperty('nopadding') ?
                props.children
                :
                <Box sx={{ p: 2 }}>{props.children}</Box>
            }
        </Box>
    )
}
export default Layout;
