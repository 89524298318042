import { Box } from "@mui/system";
import { Fragment } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import NoData from "./NoData";

export default function ScrollComponent ({ data, fetchNextPage, hasNextPage, Item }) {
    return (
        <>
            <InfiniteScroll scrollThreshold={0.5} dataLength={data?.pageParams.length * data?.pages[0]?.per_page} next={fetchNextPage} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasNextPage} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={ data?.pages[0]?.data?.length != 0 && <p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                {data?.pages?.map((page, index) => (
                        <Fragment key={index}>
                            {
                                page?.data?.length != 0 ?
                                page?.data.map((item, index) => (<Item key={index} data={item}></Item>))
                                :
                                <Box sx={{mt: 2}}>
                                    <NoData />
                                </Box>
                            }
                        </Fragment>
                    )
                )}
            </InfiniteScroll>
        </>
    )
}