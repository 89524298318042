import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Box, Dialog, DialogContent, DialogTitle, Fab, Grid, InputLabel, MenuItem, Select, TextField, } from '@mui/material'
import { IconFilter  } from '@tabler/icons-react'
import axios from 'axios';
import React, { Fragment, useContext, useState } from 'react' 
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { FilterDataContext } from '../contexts/FilterDataContext';
import { GlobalContext } from '../contexts/GlobalContext';


function FilterForm({currentTab, navigateURL}) {

    const navigate = useNavigate()

    const { url, user, token  } = useContext(GlobalContext);

    const { setFilterDataBuyer, setFilterDataSupplier,  setFilterDataFromDate, setFilterDataToDate,
          clearContextState, setFilterDataStatus, } = useContext(FilterDataContext);

    const [ modalOpen, setModalOpen ] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedBuyer, setSelectedBuyer] = useState(null)
    const [selectedSupplier, setSelectedSupplier] = useState(null)
    const [selectedFromDate, setSelectedFromDate] = useState("")
    const [selectedToDate, setSelectedToDate] = useState("") 

    const [buyerOptions, setBuyerOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const [selectedCurrentStatus, setSelectedCurrentStatus] = useState(99);


    function clearFilterData(){
        setLoading(true);
        setModalOpen(false);
        setSelectedBuyer("")
        setSelectedSupplier("");
        setSelectedFromDate();
        setSelectedToDate();
        setSelectedCurrentStatus(99);
        setBuyerOptions([]);
        setSupplierOptions([]);
        setLoading(false);
    }

    function setGlobalState(){
        !!selectedBuyer?.id && setFilterDataBuyer(selectedBuyer)        
        !!selectedSupplier?.id && setFilterDataSupplier(selectedSupplier);
        selectedFromDate ? setFilterDataFromDate(format(new Date(new Date(selectedFromDate).setHours(0,0,0,0)), 'yyyy-MM-dd HH:mm:ss')) :  setFilterDataFromDate("");
        selectedToDate ? setFilterDataToDate(format(new Date(new Date(selectedToDate).setHours(23,59,59,999)), 'yyyy-MM-dd HH:mm:ss')) :  setFilterDataToDate("");
        selectedCurrentStatus !== 99 ? setFilterDataStatus(selectedCurrentStatus) : setFilterDataStatus("allData");        
    }
    
    function handleSubmit(){
        if((selectedFromDate && selectedToDate) || (!selectedFromDate && !selectedToDate)){
            setLoading(true);
            clearContextState();
            setGlobalState()
            clearFilterData();
            setLoading(false);
            navigate(navigateURL)
        }        
    }
   
    // Fetching Buyers and Suppliers List

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                search_term: query,
                supplier: selectedSupplier?.id || "", 
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyerOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const fetchSuppliers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "supplier",
                search_term: query,
                buyer: selectedBuyer?.id || "",
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setSupplierOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }


  return (
    <Fragment>
        <Fab  color="primary" aria-label="add" onClick={() => {setModalOpen(true)}} 
            sx={{
                position: "fixed", 
                bottom: (theme) => theme.spacing(2), 
                left: (theme) => theme.spacing(2) 
            }}
            size={"medium"}
        >
            <IconFilter />
        </Fab>

        <Dialog onClose={clearFilterData} open={modalOpen}>
            <DialogTitle>Filter</DialogTitle>
            <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>

                { (currentTab?.name === "CustomOrders" ||  currentTab?.name === "Invoices") && 
                    <Alert severity="info" sx={{ mb: 4 }}>Unproccessed data will not be listed in filtered data</Alert>
                }

                <Fragment>
                    <InputLabel shrink>Buyer </InputLabel>
                    <Autocomplete 
                        sx={{ mb: 2 }}                                
                        options={ buyerOptions }
                        size="small"
                        getOptionLabel={(option) => option.name ? option.name : '' }
                        onInputChange={(event, newInputValue) => { setBuyerOptions([]); fetchBuyers(newInputValue) }}
                        value={selectedBuyer}
                        onChange={(event, newValue) => {
                            if (newValue === null) {
                                !!selectedSupplier?.id ? setSupplierOptions([selectedSupplier]) : setSupplierOptions([])
                                setBuyerOptions([])
                                setSelectedBuyer(newValue)      
                            }else{
                                setSelectedBuyer(newValue)
                            }
                        }}
                        renderInput={(params) => <TextField {...params} required={false} />}
                        isOptionEqualToValue={(option, value) => { 
                            if(!value){
                                return true
                            }else{
                                return option?.id == value?.id
                            }
                        }}
                    />
                </Fragment>
                    
                {(currentTab?.name === "Checkins" ||  currentTab?.name === "CustomOrders") &&
                <Fragment>
                    <InputLabel shrink>Supplier </InputLabel>
                    <Autocomplete 
                        sx={{ mb: 2 }}                                
                        options={ supplierOptions }
                        size="small"
                        getOptionLabel={(option) => option.name ? option.name : '' }
                        onInputChange={(event, newInputValue) => { setSupplierOptions([]); fetchSuppliers(newInputValue) }}
                        value={selectedSupplier}
                        onChange={(event, newValue) => {
                            if (newValue === null) {
                                !!selectedBuyer?.id ? setBuyerOptions([selectedBuyer]) : setBuyerOptions([])
                                setSelectedSupplier(newValue)
                                setSupplierOptions([])                                            
                            }else{
                                setSelectedSupplier(newValue)
                            }
                        }}
                        renderInput={(params) => <TextField {...params} required={false} />}
                        isOptionEqualToValue={(option, value) => { 
                            if(!value){
                                return true
                            }else{
                                return option?.id == value?.id
                            }
                        }}
                    />
                    
                </Fragment>}
                    
                <Grid container spacing={2} sx={{mb:2}}>
                    <Grid item xs={12}>
                        <InputLabel shrink>From Date</InputLabel>
                        <TextField type="date"  variant="outlined" fullWidth 
                        value={selectedFromDate}
                        size="small"
                        onChange = {(event) => {setSelectedFromDate(event?.target?.value)}}
                        InputLabelProps={{ shrink: true }} 
                        helperText={(selectedToDate && !selectedFromDate ) ? "Please enter From Date" : "" }
                        FormHelperTextProps={{error: true}}
                        required={currentTab?.name === "Orders" ? true : false}
                        InputProps={{
                            inputProps: {
                                max: selectedToDate || "", 
                            },
                            }}
                        />                        
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink>To Date</InputLabel>
                        <TextField type="date"  variant="outlined" fullWidth 
                        value={selectedToDate}
                        size="small"
                        onChange = {(event) => { setSelectedToDate(event?.target?.value) }}
                        InputLabelProps={{ shrink: true }}
                        helperText={(selectedFromDate && !selectedToDate ) ? "Please enter To Date" : "" }
                        FormHelperTextProps={{error: true}}
                        required={currentTab?.name === "Orders" ? true : false}
                        InputProps={{
                            inputProps: {
                                min: selectedFromDate || "",
                            },
                            }}
                        />                                    
                    </Grid>
                </Grid>                
                
                {
                    ( currentTab?.name === "Checkins" || currentTab?.name === "CustomOrders" || currentTab?.name === "Plans" ) && 
                    <Fragment>
                        <InputLabel shrink>Select status</InputLabel>
                        <Select
                            
                            sx={{ mb: 2 }}
                            size="small"
                            fullWidth
                            value={selectedCurrentStatus}
                            onChange={(event) => setSelectedCurrentStatus(event.target.value) }
                        >
                            {
                                Object.entries(currentTab.status).map( ([key, value], index) => (<MenuItem value={+key} key={index}>{value}</MenuItem>) )
                            }
                        </Select>
                    </Fragment>
                }

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 3 }}>
                    <LoadingButton fullWidth loading={loading} variant="contained" color={"error"} onClick={clearFilterData}>Clear Filter</LoadingButton>
                    <LoadingButton fullWidth loading={loading} disabled={!((selectedFromDate && selectedToDate) || (!selectedFromDate && !selectedToDate))} variant="contained" onClick={handleSubmit}>Filter</LoadingButton>                    
                </Box>
            </DialogContent>
        </Dialog>
    </Fragment>
  )
}

export default FilterForm