import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, Fab, InputAdornment, InputLabel, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { IconFilter, IconSearch } from '@tabler/icons-react'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/GlobalContext';

dayjs.extend(utc);
dayjs.extend(timezone);

function FilterButton({ setBuyer, setSupplier }) {

    const { url, user, token  } = useContext(GlobalContext);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [selectedBuyer, setSelectedBuyer] = useState("")
    const [selectedSupplier, setSelectedSupplier] = useState("")
    const [buyerOptions, setBuyerOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])

    const [loading, setLoading] = useState(false);

    
    function handleOpen(){
        setModalOpen(true);
    }

    function handleClose(){
        setModalOpen(false);
    }
    
    function handleSubmit(){
        setLoading(true);
        setBuyer(selectedBuyer);
        setSupplier(selectedSupplier);
        handleClose();
        setLoading(false);
    }

    function clearFilter(){
        setLoading(true);
        setSelectedBuyer("");
        setBuyer("");
        setSelectedSupplier("");
        setSupplier("");
        handleClose();
        setLoading(false);
    }

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                search_term: query,
                supplier: selectedSupplier?.id || "",
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyerOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const fetchSuppliers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "supplier",
                search_term: query,
                buyer: selectedBuyer?.id || "",
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                console.log(response.data);
                setSupplierOptions(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

  return (
    <>
        <Fab color="primary" aria-label="add" onClick={handleOpen} sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(5),
            left: (theme) => theme.spacing(5)
            }}
            size={"medium"}
        >
            <IconSearch />
        </Fab>
        <Dialog onClose={handleClose} open={modalOpen}>
            <DialogTitle>Filter</DialogTitle>
            <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                
                <InputLabel shrink>Buyer </InputLabel>
                <Autocomplete sx={{ mb: 2 }}
                    options={ buyerOptions }
                    getOptionLabel={(option) => option.name ? option.name : '' }
                    onInputChange={(event, newInputValue) => { fetchBuyers(newInputValue) }}
                    value={selectedBuyer}
                    onChange={(event, newValue) => {
                        if (newValue === null) {
                            !!selectedSupplier?.id ? setSupplierOptions([selectedSupplier]) : setSupplierOptions([])
                            setBuyerOptions([])
                            setSelectedBuyer(newValue)      
                        }else{
                            setSelectedBuyer(newValue)
                        }
                    }}
                    renderInput={(params) => <TextField {...params} required={false} />}
                    isOptionEqualToValue ={(option, value) => {if(!value){return true}else{ return value?.id == option?.id}} }
                                
                />
                
                <InputLabel shrink>Supplier </InputLabel>
                <Autocomplete sx={{ mb: 2 }}
                    options={ supplierOptions }
                    getOptionLabel={(option) => option.name ? option.name : '' }
                    onInputChange={(event, newInputValue) => { fetchSuppliers(newInputValue) }}
                    value={selectedSupplier}
                    onChange={(event, newValue) => {
                        if (newValue === null) {
                            !!selectedBuyer?.id ? setBuyerOptions([selectedBuyer]) : setBuyerOptions([])
                            setSelectedSupplier(newValue)
                            setSupplierOptions([])                                            
                        }else{
                            setSelectedSupplier(newValue)
                        }
                    }}
                    renderInput={(params) => <TextField {...params} required={false} />}
                    isOptionEqualToValue ={(option, value) => {if(!value){return true}else{ return value?.id == option?.id}} }
                                
                />
                
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                    <LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>Filter</LoadingButton>
                    <LoadingButton loading={loading} variant="contained" color="error" onClick={clearFilter}>Clear Filter</LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    </>
  )
}

export default FilterButton