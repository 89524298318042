import { Box, Paper, Typography } from "@mui/material";
import { IconCloudOff } from "@tabler/icons-react";

export default function NoData () {
    return (
        <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 2, py: 5 }}>
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{color: "#D3D3D3"}}>
                    <IconCloudOff  />
                </Box>
                <Typography variant="h6" component="h6" sx={{color: "#949494"}} >{"No Data Found!"}</Typography>
            </Box>
        </Paper>
    )
}