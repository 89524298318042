import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Chat from "./pages/Chat";
import ChatChannel from "./pages/ChatChannel";
import Cast from "./pages/Cast";
import Checkins from "./pages/Checkins";
import CustomOrders from "./pages/CustomOrders";
import { GlobalContextProvider } from './contexts/GlobalContext';
import './components/Scrollbar.css';
import { fetchToken, onMessageListener } from './firebase';
import Order from './pages/Order';
import Plans from './pages/Plans';
import Plan from './pages/Plan';
import PrivateChat from './pages/PrivateChat';
import OrderView from "./pages/OrderView";
import { QueryClient, QueryClientProvider } from 'react-query';
import { FilteredDataProvider } from './contexts/FilterDataContext';
import CheckinsPage from './pages/CheckinsPage';
import FilteredCheckins from './pages/filteredData/FilteredCheckins';
import PlansPage from './pages/PlansPage';
import FilteredPlans from './pages/filteredData/FilteredPlans';
import CustomOrdersPage from './pages/CustomOrdersPage';
import FilteredCustomOrders from './pages/filteredData/FilteredCustomOrders';

const queryClient = new QueryClient();

const darkTheme = createTheme({
  palette: {
    background: {
      default: "#f0f4f6", // "#f0f4f6"
      paper: "#fff", // 
    },
    primary: {
      main: "#044B7F", // #1d3557
    },
    secondary: {
      main: '#044B7F', // #5393ff (fixed = "#1d3557")
    },
  },
  spacing: 5,
  typography: {
    caption: {
      fontSize: 10,
      lineHeight: 1.5,
    },
    body: {
      fontSize: 12,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.5,
    },
    overline: {
      fontSize: 10,
      lineHeight: 1.5,
    },
    button: {
      fontSize: 10,
      lineHeight: 1.5,
    },
  },
});

function App() {

  // const [show, setShow] = useState(false);
  // const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  
  const navigate = useNavigate();

  // if (process.env.REACT_APP_DEV_MODE !== "development"){
    fetchToken(setTokenFound);
  
    onMessageListener().then(payload => {
      // setNotification({title: payload.notification.title, body: payload.notification.body})
      // setShow(true);
      // console.log(payload);
      if(payload.data.portal) {
        if(window.confirm("New cast request! Cast now?")) {
          navigate("/cast/" + payload.data.id)
        }
      }
    }).catch(err => console.log('failed: ', err));
  // }

  // const onShowNotificationClicked = () => {
  //    setNotification({title: "Notification", body: "This is a test notification"})
  //    setShow(true);
  // }


  return (
    <QueryClientProvider client={queryClient} >
      <ThemeProvider theme={darkTheme}>
        <GlobalContextProvider>
          <FilteredDataProvider>
              <CssBaseline />
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/checkins/:status" element={<CheckinsPage />} />
                <Route path="/plans/:status" element={<PlansPage />} />
                <Route path="/plan/:id" element={<Plan />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/chat/channel/:id" element={<ChatChannel />} />
                <Route path="/cast" element={<Cast />} />
                <Route path="/customorders/:status" element={<CustomOrdersPage />} />
                <Route path="/order/:id" element={<Order />} />
                <Route path="/privatechat" element={<PrivateChat/>} />
                <Route path="/orderview/:id" element={<OrderView />} /> 
                <Route path="/filtered_checkins" element={<FilteredCheckins />} /> 
                <Route path="/filtered_plans" element={<FilteredPlans />} />
                <Route path="/filtered_custom_orders" element={<FilteredCustomOrders />} />

              </Routes>
          </FilteredDataProvider>
        </GlobalContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
