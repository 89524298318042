import React, { useState, useEffect, useContext } from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid'; // Grid version 1
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import LoadingComponent from '../components/LoadingComponent';

function Login() {
    const navigate = useNavigate();
    const { url, setUser, setUserName, loggedIn, setLoggedIn, setToken, setMasters  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        setLoading(true);
        if(loggedIn) {
            axios.post(url + "api/boot", {

            })
            .then(function (response) {
                if(response.data.error === 0) {
                    setMasters(response.data.masters)
                }
                return navigate("/dashboard");
            })
            .catch(function (error) {
                console.log(error);
            });      
        } else {
            setLoading(false);
        }
    }, []);

    const formAuth = (event) => {
        event.preventDefault();
        axios.post(url + "api/admin/auth", {
            username: username,
            password: password,
        })
        .then(function (response) {
            if(response.data.error === 0) {
                setUser(response.data.user)
                setUserName(response.data.name)
                setMasters(response.data.masters)
                setLoggedIn(true);
                setToken(response.data.token);
                return navigate("/dashboard");
            } else {
                setError(true);
                setErrorMessage(response.data.message);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Error = () => {
        if(error) {
            return (
                    <Box mb={3}>
                        <Alert variant="filled" severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {errorMessage}
                        </Alert>
                    </Box>
            )
        }
    }

    return (
        <Grid container spacing={0} direction="row"justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
            {  loading ? 
                <LoadingComponent loading={loading} />
            : 
                <Grid item xs={9} md={4}>
                    <Typography variant="h4" mb={3} component="h4">Login</Typography>
                    <Error></Error>
                    <form onSubmit={formAuth}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            <Typography variant="subtitle1" component="p">Enter you credentials to get started.</Typography>
                            <TextField id="outlined-basic" label="Username" variant="outlined" value={username} onChange={e => setUsername(e.target.value)} />
                            <TextField type="password" id="outlined-basic" label="Password" variant="outlined" value={password} onChange={e => setPassword(e.target.value)} />
                            <Button type="submit" variant="contained" size="large">Login</Button>
                        </Box>
                    </form>
                </Grid>
            }
         </Grid>
      );
}

export default Login;
