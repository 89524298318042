import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../components/Layout';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import Fab from '@mui/material/Fab';
import { IconPlus, IconSearch } from '@tabler/icons-react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { format, formatDistance, parse } from 'date-fns'
import FilterButton from '../components/chat/FilterButton';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LoadingComponent from '../components/LoadingComponent';





export default function PrivateChat(){
    
    const navigate = useNavigate();
    const { url, user, token } = useContext(GlobalContext);

    const [buyer, setBuyer] = useState("");
    const [supplier, setSupplier] = useState("");

    const [loading, setLoading] = useState(true);
 
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData(1);
    }, [ buyer, supplier ]);

    const fetchData = (page) => {
        axios.post( url + 'api/admin/rasiklal-chat?page=' + page, {
            user: user,
            buyer: buyer,
            supplier: supplier,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            // console.log({initialData: response.data});
            setLoading(false);
            setItems((prev) => page != 1 ? [...prev, ...response.data.data] : response.data.data);
            
            setCurrentPage(response.data.current_page);
            if(response.data.current_page == response.data.last_page) {
                setHasMore(false);
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally( () => setLoading(false));
    }

    // console.log({items})

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    
    function stringAvatar(name) {
        var split = name.split(' ');
        return {
            sx: {
            bgcolor: stringToColor(name),
            },
            children: `${split[0][0]}${split[1] ? split[1][0] : ''}`,
        };
    }


    const Item = (prop) => {

        // console.log({itemProp: prop})
        
        let read = prop.data.read_rasiklal;
        return (
            <Box onClick={event => { return navigate("/chat/channel/" + prop.data.name) }} sx={{ borderBottom: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'left', px: 3, py: 2 }}>
                 <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar {...stringAvatar(prop.data.customer_name)} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="body" component="p">{ prop.data.customer_name }, <span style={{ fontWeight: "lighter", fontSize: "0.8em", color: "gray"}}>{prop?.data?.customer_details?.city_details?.name}</span></Typography>
                        <Typography variant="caption" component="p">{prop.data.last_update ? formatDistance(new Date(prop.data.last_update), new Date(), { addSuffix: true }) : '-'}</Typography>
                    </Box>
                    { read == 0 &&
                        <Badge color="secondary" overlap="circular" badgeContent=" " variant="dot"></Badge>
                    }
                </Stack>
            </Box>
        )
    }

    function New() {

        const [open, setOpen] = useState(false)
        const [customer, setCustomer] = useState("")
        const [customers, setCustomers] = useState([])

        const handleOpen = () => setOpen(true)

        const handleClose = () => setOpen(false)
        

        const handleSubmit = (event) => {
            event.preventDefault();
            axios.post( url + 'api/chat/channel', {
                user: user,
                customer: customer.id,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                handleClose()
                navigate("/chat/channel/" + response.data.channel.name);
            })
            .catch(function (error) {
                console.log(error);
            });
        }     

        const fetchCustomers = (query) => {
            if (query.length) {
                axios.post( url + 'api/admin/chat/customers', {
                    user: user,
                    query: query
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    setCustomers(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }        

        return(
            <React.Fragment>
                <Fab color="primary" aria-label="add" onClick={handleOpen} 
                    sx={{ 
                        position: "fixed", 
                        bottom: (theme) => theme.spacing(5), 
                        right: (theme) => theme.spacing(5) 
                    }}
                    size={"medium"}
                >
                    <IconPlus />
                </Fab>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>New Conversation</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                        <form onSubmit={handleSubmit}>
                            <InputLabel shrink>Customer</InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                options={ customers }
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                datasource={customers}
                                onInputChange={(event, newInputValue) => { fetchCustomers(newInputValue) }}
                                value={customer}
                                onChange={(event, newValue) => {
                                    setCustomer(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} required={true} />}
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                                <Button type="submit" variant="contained" sx={{p: 2}}>Start</Button>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }

    const [customer, setCustomer] = useState("");

    function SearchFilter ({setCustomer}) {

        const [ modalOpen, setModalOpen ] = useState(false);
        const [selectedCustomer, setSelectedCustomer] = useState("");
        const [customerOptions, setCustomerOptions] = useState([]);
        const [loading, setLoading] = useState(false);

        const handleOpen = () => setModalOpen(true);
        const handleClose = () => setModalOpen(false);

        function handleSubmit(){
            setLoading(true);
            setCustomer(selectedCustomer)
            handleClose();
            setLoading(false);
        }

        function clearFilter(){
            setLoading(true);
            setSelectedCustomer("");
            setCustomer("");
            handleClose();
            setLoading(false);
        }

        const fetchCustomers = (query) => {
            if (query.length) {
                axios.post( url + 'api/admin/chat/customers', {
                    user: user,
                    query: query
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    setCustomerOptions(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }

        // console.log({customerOptions})

        return(
            <>
                <Fab color="primary" aria-label="add" onClick={handleOpen} sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(5),
                left: (theme) => theme.spacing(5)
                }}
                size={"medium"}
                > 
                    <IconSearch />
                </Fab>
                <Dialog onClose={handleClose} open={modalOpen}>
                    <DialogTitle>Filter</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>                
                        <InputLabel shrink>Customer</InputLabel>
                        <Autocomplete sx={{ mb: 2 }}
                            options={ customerOptions }
                            getOptionLabel={(option) => option.name ? option.name : '' }
                            datasource={customerOptions}
                            onInputChange={(event, newInputValue) => { fetchCustomers(newInputValue) }}
                            value={selectedCustomer}
                            onChange={(event, newValue) => {
                                setSelectedCustomer(newValue)
                            }}
                            renderInput={(params) => <TextField {...params} required={false} />}
                        />                
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                            <LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>Filter</LoadingButton>
                            <LoadingButton loading={loading} variant="contained" color="error" onClick={clearFilter}>Clear Filter</LoadingButton>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        )
    
    }

    return(
        <>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Private Chat" nopadding={true}>
                <InfiniteScroll dataLength={items.length} next={() => fetchData(currentPage + 1)} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore}
                 loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} scrollableTarget="scrollableDiv" endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                    {items.map((row, index) => (
                        <Item key={row.id} data={row}></Item>
                    ))}
                </InfiniteScroll>
                <SearchFilter setCustomer={setCustomer} />
                <New />
            </Layout>
        }        
        </>
    )
}

