import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../components/Layout';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns'
import UploadButton from '../components/VoucherUploader';
import CheckinButton from '../components/CheckinButton';
import FilterButton from '../components/FilterButton';
import NewCheckinButton from '../components/NewCheckinButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import MediaGrid from '../components/MediaGrid';
import { Alert, Chip, DialogActions, Snackbar, useMediaQuery } from '@mui/material';
import LoadingComponent from '../components/LoadingComponent';
import FilterView from '../components/FilterView';
import { FilterDataContext } from '../contexts/FilterDataContext';
import { useInfiniteQuery } from 'react-query';
import ErrorMessage from '../components/ErrorMessage';
import { IconCalendar } from '@tabler/icons-react';
import ScrollComponent from '../components/ScrollComponent';
import FilterForm from '../components/FilterForm';
import BackToTop from '../components/BackToTop';
import { LoadingButton } from '@mui/lab';


export default function CheckinsPage() {
    let { status } = useParams();
    const navigate = useNavigate();

    const isMobile = useMediaQuery('(max-width:600px)');

    const { url, user, token } = useContext(GlobalContext);
    const { setFilterDataBuyer, setFilterDataSupplier,  setFilterDataFromDate,
        filterDataBuyer, filterDataSupplier, filterDataFromDate, filterDataToDate, filterDataStatus,
        setFilterDataToDate, clearContextState, setFilterDataStatus } = useContext(FilterDataContext);
    
    const title = "Check-Ins";

    // console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate, filterDataToDate, filterDataStatus,})
    
    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/admin/visits?page=' + page, {
            status: pageStatus,
            user: user,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['checkins', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    // console.log({isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch})

    const currentTab = { name: "Checkins", status: { 99: "All Data", 0: "Upcoming", 1: "Checked-in", 2: "Ordered", 3: "Cancelled", 4: "Not Ordered", 5: "Not Visited", }};

    const Item = ({data}) => {
        
        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 2 }}>
                <Grid container>
                    <Grid item xs={6}  sx={{ borderBottom: 1, borderRight: 1,  borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p">Buyer</Typography>
                        <Typography variant="body" component="p">{data?.buyer_name}{!!data?.buyer_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.buyer_city_details?.name}</span>}</Typography>
                        {!!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>}
                    </Grid>
                    <Grid item xs={6}  sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p">Supplier</Typography>
                        <Typography variant="body" component="p">{data?.seller_name}{!!data?.seller_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.seller_city_details?.name}</span>}</Typography>
                        {!!data?.seller_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.seller_msme} /></Box>} 
                    </Grid>
                    <Grid item xs={12} sx={{borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{ (data?.status_label === "Upcoming" || data?.status_label === "Checked-In" || data?.status == 1 || data?.status == 2) ? "Plan Date" : "Date"}</Typography>
                        <Typography variant="body" component="p">{!!data?.date ? format(parse(data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy HH:mm:ss') : ''}</Typography>
                    </Grid>
                    {data?.status_label === "Checked-In" &&
                        <Grid item xs={12} sx={{borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Check-in Date</Typography>
                            <Typography variant="body" component="p">{!!data?.checkin_on ? format(new Date(data?.checkin_on), 'dd MMM yyyy HH:mm:ss') :"-"}</Typography>
                        </Grid>
                    }
                    <Grid item xs={12} sx={{borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">{status == 2 ? "Check-in Remarks" : "Remarks"}</Typography>
                        <Typography variant="body" component="p">{data.remarks ? data.remarks : '-'}</Typography>
                    </Grid>

                    {
                        data?.order_details && data?.order_details !== null ? 
                        <>
                        {
                                data.order_details?.remarks ? 
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Order Remarks</Typography>
                                    <Typography variant="body" component="p">{ data.order_details.remarks }</Typography>
                                </Grid> 
                                :
                                <></>
                        }
                        {data?.order_details?.images &&
                            <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                <MediaGrid dir="orders" images={data?.order_details?.images} />
                            </Grid>
                        }
                        <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                            {data?.order_details?.show_button ?
                                <Button variant="contained" fullWidth  
                                onClick={() => { if(status != 0) { navigate("/orderview/" + data?.order_details.id) } }}>View Order</Button>
                                 :
                                <Alert severity="warning">This order data is being processed</Alert>
                            }
                        </Grid>
                        </> 
                        : 
                        <>
                        { data?.entry_details && data?.entry_details !== null ? 
                            <>
                            {
                                data.entry_details?.remarks ? 
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <Typography variant="overline" component="p">Order Remarks</Typography>
                                    <Typography variant="body" component="p">{ data.entry_details.remarks }</Typography>
                                </Grid> : <></>
                            }
                            {data?.entry_details.images &&
                                <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                    <MediaGrid dir="orders" images={data?.entry_details.images} />
                                </Grid>
                             }
                            <Grid item xs={12} sx={{borderTop: 1,borderColor: 'divider', px: 1, py:1 }}>
                                <Alert severity="warning">This order data is being processed</Alert> 
                            </Grid>
                            </>
                            : 
                            <></> 
                        }
                        </>
                    }


                </Grid>
                {data?.status != 3 &&
                    <Box sx={{ borderTop: 1,  borderColor: 'divider',  display: 'flex', flexDirection: 'column', gap: 3, p: 1 }}>
                        <ItemAction status={data.status} id={data.id} order={data.order_id} buyer={data.buyer} seller={data.seller} />
                    </Box>
                }
            </Paper>
        )
    }

    function ItemAction (prop) {
        if(prop.status == 1) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "stretch", flexDirection: 'row', gap: 1,  }}>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="6"  id={prop.id} title="Mark Not Visited" />
                        </Box>                        
                        <Box sx={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center",}}>
                            <CheckinButton creator="2" id={prop.id} refetch={refetch} />
                        </Box> 
                    </Box>);
        } else if (prop.status == 2) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center',alignItems: "stretch", flexDirection: 'row', gap: 1 }}>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="5" id={prop.id} title="Mark Not Ordered" />
                        </Box>                        
                        <Box sx={{flex: 1,display: "flex", justifyContent: "center", alignItems: "center",}}>
                            <UploadButton refetch={refetch} buttonType="button" title="Order" seller={prop.seller} buyer={prop.buyer} link={prop.id} voucher_type="orders" />
                        </Box>
                    </Box>);
        } else if (prop.status == 4) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "stretch", flexDirection: 'row', gap: 1 }}>
                        <Box sx={{flex: 1}}> 
                            <MarkStatus status="6" id={prop.id} title="Mark Not Visited" /> 
                        </Box>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="5" id={prop.id} title="Mark Not Ordered" />
                        </Box>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="1" id={prop.id} title="Mark Pending" />
                        </Box>
                    </Box>);
        } else if (prop.status == 5) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 1 }}>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="1" id={prop.id} title="Mark Pending" />
                        </Box>
                        <Box sx={{flex: 1}}>
                            <MarkStatus status="4" id={prop.id} title="Mark Canceled" />
                        </Box>
                    </Box>);
        } else if (prop.status == 6) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 1 }}>
                        <Box sx={{flex: 1}}><MarkStatus status="1" id={prop.id} title="Mark Pending" /></Box>
                        <Box sx={{flex: 1}}><MarkStatus status="4" id={prop.id} title="Mark Canceled" /></Box>
                    </Box>);
        }
    }

    function MarkStatus ({status, id, title}) {

        const [open, setOpen] = useState(false);
        const [loading, setLoading] = useState(false);
        const [isError, setIsError] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");

        const handleOpen = () => {
            setOpen(true);
        }
    
        const handleClose = () => {
            setOpen(false);
            setLoading(false);
        }

        const clearError = () => {
            setIsError(false);
            setErrorMessage("");
        }

        const updateStatus = () => {
            setLoading(true);
            axios.post( url + 'api/admin/checkin/status', {
                user: user,
                id: id,
                status: status
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                if(response.data.error == 0) {
                    handleClose();
                    refetch();
                } else {
                    setIsError(true);
                    setErrorMessage(response.data?.message);
                    handleClose();
                }
            })
            .catch(function (error) {
                setIsError(true);
                setErrorMessage(error?.message);
                console.log(error);
                handleClose();
            });
        }

        return (
            <Box sx={{width: "100%", height: "100%"}}>
                <Button variant="contained" size="small" sx={{height: "100%", fontSize: isMobile ? "10px" : "12px"}} fullWidth onClick={handleOpen}>{title}</Button>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle sx={{fontWeight: "600"}}>{title}</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                        <Typography variant="body2" >Do you really want to change the status of this visit?</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 3 }}>
                            <Button loading={loading} sx={{ fontSize: isMobile ? "10px" : "14px" }} size="small" variant="contained" color="error" fullWidth onClick={handleClose}>No</Button>
                            <LoadingButton loading={loading} sx={{ fontSize: isMobile ? "10px" : "14px" }} size="small" variant="contained" fullWidth onClick={updateStatus}>Yes</LoadingButton>
                        </Box>
                    </DialogContent>
                </Dialog>
                {isError &&
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isError} autoHideDuration={3000} onClose={clearError}>
                    <Alert severity="error" sx={{ width: '100%' }} >
                        {errorMessage}
                    </Alert>
                </Snackbar>
                }
            </Box>
        )
    }

    function handleTodayData() { 
        clearContextState();
        setFilterDataFromDate(format(new Date(new Date().setHours(0,0,0,0)), 'yyyy-MM-dd HH:mm:ss'));
        setFilterDataToDate(format(new Date(new Date().setHours(23,59,59,999)), 'yyyy-MM-dd HH:mm:ss'));
        setFilterDataStatus(status)
        navigate("/filtered_checkins")        
    }

    return (
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={isLoading} />
                : 
                <Layout title={title} backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/checkins/" + newStatus); }}>
                            <Tab label="Upcoming" />
                            <Tab label="Checked-In" />
                            <Tab label="Ordered" />
                            <Tab label="Cancelled" />
                            <Tab label="Not Ordered" />
                            <Tab label="Not Visited" />
                        </Tabs>

                        { data?.pages[0]?.data?.length !== 0 && 
                        <Box sx={{ paddingTop: "10px",paddingRight: "5px", display: "flex", justifyContent: "end", alignItems: "center"}}>
                            <Button variant="contained" onClick={handleTodayData}  >
                                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: 1}}>
                                    <Box>< IconCalendar /></Box>
                                    <Box>Today's Check-Ins</Box>
                                </Box>                            
                            </Button>
                        </Box>
                        }                

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />

                    </Box>
                    
                    <FilterForm currentTab={currentTab} navigateURL={"/filtered_checkins"} />

                    <NewCheckinButton title="Check-In" 
                        refetch={refetch}
                        createdInAreas={[ 
                            {label : "Admin App"},
                            {label : "Add Button"},
                        ]} 
                    />

                    <BackToTop />
                    
                </Layout>
            }
            </>
            }
        </>
    )
}