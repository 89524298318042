import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../components/Layout';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import Fab from '@mui/material/Fab';
import { IconPlus, IconForms } from '@tabler/icons-react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { format, formatDistance, parse } from 'date-fns'
import FilterButton from '../components/chat/FilterButton';
import LoadingComponent from '../components/LoadingComponent';

function Chat() {
    const navigate = useNavigate();
    const { url, user, token, setUser, setUserName, loggedIn, setLoggedIn, setToken  } = useContext(GlobalContext);

    const [buyer, setBuyer] = useState("");
    const [supplier, setSupplier] = useState("");

    const [loading, setLoading] = useState(true);
 
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);


    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData(1);
    }, [ buyer, supplier ]);


    const fetchData = (page) => {
        axios.post( url + 'api/admin/chat?page=' + page, {
            user: user,
            buyer: buyer,
            supplier: supplier,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setLoading(false);
            setItems((prev) => page != 1 ? [...prev, ...response.data.data] : response.data.data);
            setCurrentPage(response.data.current_page);
            if(response.data.current_page == response.data.last_page) {
                setHasMore(false);
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally( () => setLoading(false));
    }

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    
    function stringAvatar(name) {
        var split = name.split(' ');
        return {
            sx: {
            bgcolor: stringToColor(name),
            },
            children: `${split[0][0]}${split[1] ? split[1][0] : ''}`,
        };
    }

    const Item = (prop) => { 
        return (
            <Box onClick={event => { return navigate("/chat/channel/" + prop.data.name) }} sx={{ borderBottom: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'left', px: 3, py: 2,  }}>
                  <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center" alignContent="center"><Grid item xs={6}>
                        <Paper sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: 1, px: 1, py: 1, borderRadius: 10, overflow: "hidden" }}>
                            <Avatar {...stringAvatar(prop.data.buyer_name)} sx={{ width: 22, height: 22, fontSize: 10 }} />
                            <Box >
                                <Typography variant="body" component="p" sx={{whiteSpace: "nowrap" ,overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%"}}>{ prop.data.buyer_name }</Typography>
                                <Typography variant="caption" component="p">Buyer</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: 1, px: 1, py: 1, borderRadius: 10, overflow: "hidden" }}>
                            <Avatar {...stringAvatar(prop.data.seller_name)} sx={{ width: 22, height: 22, fontSize: 10 }} />
                            <Box >
                                <Typography variant="body" component="p" sx={{whiteSpace: "nowrap", width: "100%", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%"}}>{ prop.data.seller_name }</Typography>                               
                                <Typography variant="caption" component="p">Supplier</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    {/* <Typography variant="caption" component="p">{prop.data.last_update ? formatDistance(new Date(prop.data.last_update), new Date(), { addSuffix: true }) : '-'}</Typography> */}
                  </Grid>
            </Box>
        )
    }

    function New() {
        const [open, setOpen] = useState(false)
        const [buyer, setBuyer] = useState("")
        const [supplier, setSupplier] = useState("")
        const [buyers, setBuyers] = useState([])
        const [suppliers, setSuppliers] = useState([])

        const handleOpen = () => {
            setOpen(true);
        }

        const handleClose = () => {
            setOpen(false);
        }

        const handleSubmit = (event) => {
            event.preventDefault();
            axios.post( url + 'api/chat/channel', {
                user: user,
                buyer: buyer.id,
                seller: supplier.id,
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                handleClose()
                navigate("/chat/channel/" + response.data.channel.name);
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        const fetchBuyers = (query) => {
            if (query.length) {
                axios.post( url + 'api/admin/customers', {
                    mode: "buyer",
                    search_term: query,
                    supplier: supplier?.id || "",                
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    setBuyers(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }

        const fetchSuppliers = (query) => {
            if (query.length) {
                axios.post( url + 'api/admin/customers', {
                    mode: "supplier",
                    search_term: query,
                    buyer: buyer?.id || "",
                }, { headers: { Authorization: `Bearer ${token}` }})
                .then(function (response) {
                    setSuppliers(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }

        // console.log({buyer, buyers, supplier, suppliers})

        return(
            <React.Fragment>
                <Fab color="primary" aria-label="add" onClick={handleOpen} 
                    sx={{ 
                        position: "fixed", 
                        bottom: (theme) => theme.spacing(5), 
                        right: (theme) => theme.spacing(5) 
                    }}
                    size={"medium"}
                >
                    <IconPlus />
                </Fab>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>New Conversation</DialogTitle>
                    <DialogContent sx={{ width: '80vw', maxWidth: '300px' }}>
                        <form onSubmit={handleSubmit}>
                            <InputLabel shrink>Buyer</InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                options={ buyers }
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(event, newInputValue) => { fetchBuyers(newInputValue) }}
                                value={buyer}
                                onChange={(event, newValue) => {
                                    if (newValue === null) {
                                        !!supplier?.id ? setSuppliers([supplier]) : setSuppliers([])
                                        setBuyer(newValue)
                                        setBuyers([])                                            
                                    }else{
                                        setBuyer(newValue)
                                    }                                    
                                }}
                                renderInput={(params) => <TextField {...params} required={true} />}
                                isOptionEqualToValue={(option, value) => { 
                                    if(!value){
                                        return true
                                    }else{
                                        return option?.id == value?.id
                                    }
                                }}
                            />

                            <InputLabel shrink>Supplier</InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                options={ suppliers }
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(event, newInputValue) => { fetchSuppliers(newInputValue) }}
                                value={supplier}
                                onChange={(event, newValue) => {
                                    if (newValue === null) {
                                        !!buyer?.id ? setBuyers([buyer]) : setBuyers([])
                                        setSupplier(newValue)
                                        setSuppliers([])                                            
                                    }else{
                                        setSupplier(newValue)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} required={true} />}
                                isOptionEqualToValue={(option, value) => { 
                                    if(!value){
                                        return true
                                    }else{
                                        return option?.id == value?.id
                                    }
                                }}
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                                <Button type="submit" variant="contained">Start</Button>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }

    return (
        <div>
        {  loading ? <LoadingComponent loading={loading} />
            : 
            <Layout title="Chat" nopadding={true}>
                <InfiniteScroll dataLength={items.length} next={() => fetchData(currentPage + 1)} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore}
                 loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} scrollableTarget="scrollableDiv" endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                    {items.map((row, index) => (
                        <Item key={row.id} data={row}></Item>
                    ))}
                </InfiniteScroll>
                <FilterButton setBuyer={setBuyer} setSupplier={setSupplier} />
                <New />
            </Layout>
        }
        </div>
    );
}

export default Chat;
