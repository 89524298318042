import { Alert, AlertTitle, Button, Chip, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import LoadingComponent from "../../components/LoadingComponent";
import ScrollComponent from "../../components/ScrollComponent";
import { GlobalContext } from "../../contexts/GlobalContext";
import BackToTop from "../../components/BackToTop";
import ErrorMessage from "../../components/ErrorMessage";
import { FilterDataContext } from "../../contexts/FilterDataContext";



export default function FilteredPlans(){

    const navigate = useNavigate();

    const { filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, } = useContext(FilterDataContext);
    
    const { url, user, token,  } = useContext(GlobalContext);

    const title = "Filtered Plans";

    const isMobile = useMediaQuery('(max-width:600px)');

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/admin/plans?page=' + page, {
            user: user,
            buyer: filterDataBuyer?.id ? filterDataBuyer?.id : "",
            from_date: filterDataFromDate,
            to_date: filterDataToDate,
            status: filterDataStatus === "allData" ? "" : filterDataStatus.toString(),
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    } 
        
    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'filteredPlans',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    const currentTab = { name: "Plans", status: { 99: "All Data", 0:"In-Progress", 1: "Completed", 2: "Cancelled",}};

    // console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus,})
    
    const Item = ({data}) => {

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                {
                    filterDataStatus === 'allData' && 
                    <Box sx={{py:1}}>
                        <Chip label={data?.status_label} />
                    </Box>
                }
                <Grid container sx={{borderTop: 1,borderColor: 'divider'}}>
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Plan #</Typography>
                        <Typography variant="body" component="p">{data?.id}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Buyer</Typography>
                        <Typography variant="body" component="p">{data?.buyer_name}{ !!data?.buyer_city_details?.name && <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>, {data?.buyer_city_details?.name}</span>}</Typography>
                            {
                                !!data?.msme  &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.msme} /></Box>
                            }
                    </Grid>
                    <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{!!data.date ? format(parse(data.date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : '-'}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Return Date</Typography>
                        <Typography variant="body" component="p">{!!data.return_date ? format(parse(data.return_date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy  hh:mm a') : '-'}</Typography>
                    </Grid>                    
                </Grid>
                <Box sx={{ borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1, display: "flex", gap:1}}>
                        <Box sx={{flex: 1}}>
                            <Button variant="contained" fullWidth={isMobile ? true :  false} onClick={() => {navigate("/plan/" + data?.id)}} sx={isMobile ? {} : {px: 5}} >View Plan</Button>
                        </Box>
                </Box>
            </Paper>
        )
    }

    if(filterDataStatus === ""){
        navigate(-1, { replace: true })
        return null;
    }
    
    return(
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
                <>            
                {  isLoading ? 
                <LoadingComponent loading={isLoading} />
                : 
                <Layout title={title} backToTop={true}>                
                    <Box>
                        <Alert severity="success" >
                            <AlertTitle sx={{fontSize: "0.5 em", fontWeight: "bold"}}> Results </AlertTitle>
                            <>
                                {filterDataBuyer &&  <Typography variant="subtitle2"><b>Buyer :</b> {filterDataBuyer?.name?.toUpperCase()}</Typography>}
                                {filterDataSupplier  && <Typography variant="subtitle2"><b>Supplier :</b> {filterDataSupplier?.name?.toUpperCase()}</Typography>}
                            </>
                            {
                                (filterDataFromDate && filterDataToDate) && 
                                    <Typography variant="subtitle2"> <b>From:</b> {format(new Date(filterDataFromDate), 'dd/MM/yyyy')} <b>To:</b> {format(new Date(filterDataToDate), 'dd/MM/yyyy')}</Typography>
                            }    
                            <Box sx={{mt: 1}}>
                                { filterDataStatus == "allData" ? `Status: ${currentTab.status[99]}`: `Status: ${currentTab.status[filterDataStatus]}`}
                            </Box>
                        </Alert>                    
                    </Box>                
                    <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    <BackToTop alone={true} />
                </Layout>
                }
                </>
            } 
        </>       
    )
}