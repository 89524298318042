import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";

const firebaseConfig = {
    apiKey: "AIzaSyAhjWHi5wtNHnXfpCdOC1o2gpFGeKc-6YY",
    authDomain: "rasiklal-d97b5.firebaseapp.com",
    projectId: "rasiklal-d97b5",
    storageBucket: "rasiklal-d97b5.appspot.com",
    messagingSenderId: "351510485979",
    appId: "1:351510485979:web:1a53994f9b34602384e5fe"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {

    return getToken(messaging, { vapidKey: 'BA5Xn0Qo7xJ45hU4xQYlGxF8IHuCzRdXjhYNMMtL21Ist3D28i4EUarvSrgMJJEK2qBsXtG03CdBwj6NBGPjgNg' }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            localStorage.setItem("fcm_token", currentToken);
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}
  

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });