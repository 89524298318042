import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../components/Layout';
import { GlobalContext } from '../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import { IconMessage, IconMapPin, IconLogout, IconSettings, IconTools, IconCalendarEvent, IconFileUpload, IconCast } from '@tabler/icons-react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailLockOutlinedIcon from '@mui/icons-material/MailLockOutlined';
import LoadingComponent from '../components/LoadingComponent';
import UploadButton from '../components/VoucherUploader';

function Dashboard() {
    const navigate = useNavigate();
    const { url, setUser, user, username, loggedIn, setLoggedIn, setToken, token, setUserName, setPushToken, setMasters  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        pushToken();
    }, []);

    const pushToken = () => {
        var push = 0;
        if (localStorage.getItem("fcm_pushed") === null) {
            push = 1;
        } else {
            if (localStorage.getItem("fcm_pushed_token") === localStorage.getItem("fcm_token")) {
                push = 0;
            } else {
                push = 1;
            }
        }
        if(push === 1) {
            var fcm_token = localStorage.getItem("fcm_token");
            console.log("fcm_token", fcm_token);
            axios.post( url + 'api/admin/token', {
                user: user,
                token: fcm_token
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                localStorage.setItem("fcm_pushed", true);
                localStorage.setItem("fcm_pushed_token", fcm_token);
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const logout = () => {
        setLoading(true);
        setUser(""); 
        setToken(""); 
        setLoggedIn("");
        setUserName("");
        setPushToken("");
        setMasters("");
        localStorage.removeItem("fcm_pushed")
        localStorage.removeItem("fcm_pushed_token")
        localStorage.removeItem("fcm_token")
        navigate("/");
        setLoading(false);
    }

    if(!loggedIn){
        navigate("/");
        return null;
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Dashboard" hideNavigation={true}>
                <Grid container spacing={2} direction="row" justifyContent="start" alignItems="center" alignContent="center">
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("/cast") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }}>
                            <IconCast></IconCast>
                            <Typography variant="body1" component="p">Cast</Typography>
                        </Card>
                    </Grid>                    
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("/chat") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }}>
                            <IconMessage></IconMessage>
                            <Typography variant="body1" component="p">Chat</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card onClick={event => {return navigate("/privatechat")}} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }}>
                            <MailLockOutlinedIcon />
                            <Typography variant="body1" component="p">Private Chat</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("/checkins/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }}>
                            <LocationOnOutlinedIcon></LocationOnOutlinedIcon>
                            <Typography variant="body1" component="p">Check-Ins</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card onClick={event => { return navigate("/plans/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }}>
                        <IconCalendarEvent />
                            <Typography variant="body1" component="p">Plans</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card onClick={() => { return navigate("/customOrders/0") }} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }}>
                            <IconTools />
                            <Typography variant="body1" component="p">Custom Orders</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <UploadButton 
                            buttonType="card" icon={<IconFileUpload />} title="Order Form" voucher_type="orders" quick={true}  
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Card onClick={logout} sx={{ cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', px: 4, py:4 }}>
                            <IconLogout></IconLogout>
                            <Typography variant="body1" component="p">Logout</Typography>
                        </Card>
                    </Grid>
                </Grid>
            </Layout>
        }
        </div>
    );
}

export default Dashboard;
